import React from 'react';
import HsLink from '../../_common/hs-link/hs-link';
import { OrderErrorStoryblok } from '../../../_shared/interfaces/storyblok';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';

import * as OrderErrorStyles from './error.module.scss';
import { navigate } from 'gatsby';

const OrderError = ({ blok, storyblokCommon }: { blok: OrderErrorStoryblok; storyblokCommon: StoryblokCommon }) => {
    return (
        <div className={OrderErrorStyles.orderError}>
            <div className={OrderErrorStyles.box}>
                <h4>{blok.headline}</h4>

                <p>{blok.copytext}</p>

                <div>
                    <HsLink
                        onClick={() => navigate(self.location.pathname, { state: { step: 'overview' } })}
                        storyblokCommon={storyblokCommon}
                    >
                        {blok.overview_link_text}
                    </HsLink>
                </div>
            </div>
        </div>
    );
};

export default OrderError;
