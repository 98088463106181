import React, { ChangeEvent } from 'react';
import { OrderContext } from '../../../../context/order/order.context';
import { getRichTextMarkup, StoryblokCommon } from '../../../../lib/storyblok/storyblok';
import { OrderServicesAdditionalCostsStoryblok } from '../../../../_shared/interfaces/storyblok';

import * as OrderServicesAdditionalCostsStylesHs from './additional-costs.hs.module.scss';
import * as OrderServicesAdditionalCostsStylesDr from './additional-costs.dr.module.scss';

import * as FormStylesHs from '../../../../_scss/modules/_forms.hs.module.scss';
import * as FormStylesDr from '../../../../_scss/modules/_forms.dr.module.scss';
import { trackEvent } from '../../../../lib/track/track';

export const OrderServicesAdditionalCosts = ({
    blok,
    storyblokCommon,
}: {
    blok: OrderServicesAdditionalCostsStoryblok;
    storyblokCommon: StoryblokCommon;
}) => {
    const { order, updateOrder } = React.useContext(OrderContext);
    const [additionalCosts, setAdditionalCosts] = React.useState<string | undefined>(order.additionalCosts ? order.additionalCosts : '0');
    const OrderServicesAdditionalCostsStyles =
        storyblokCommon.configuration.content.theme === 'dr' ? OrderServicesAdditionalCostsStylesDr : OrderServicesAdditionalCostsStylesHs;
    const FormStyles = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr : FormStylesHs;

    const [trackAdditionalCosts, setTrackAdditionalCosts] = React.useState(true);
    const additionalCostsChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const re = /^[+]?\d+([.]\d+)?$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            let value = parseInt(event.target.value ?? '0', 10);
            if (isNaN(value)) {
                value = 0;
            }
            setAdditionalCosts(value.toString());
            updateOrder({ additionalCosts: value.toString() });
        }
        if (trackAdditionalCosts) {
            setTrackAdditionalCosts(false);
            trackEvent('serviceorder.fieldChanged', {
                serviceorder: { type: 'workshop_service', name: '1:ServiceSelection' },
                serviceorderField: { name: 'Repair Cost Authorization' },
            });
        }
    };

    return (
        <>
            {OrderServicesAdditionalCostsStyles && FormStyles && (
                <div className={OrderServicesAdditionalCostsStyles.orderServicesAdditionalCosts}>
                    <h5>{blok.headline}</h5>
                    <p>{blok.copytext}</p>

                    <div className={[FormStyles.formField, OrderServicesAdditionalCostsStyles.formField].join(' ')}>
                        <div>
                            <label htmlFor="order-additional-costs" dangerouslySetInnerHTML={getRichTextMarkup(blok.label)} />
                        </div>
                        <div>
                            <div className={FormStyles.inputAppend} data-append={storyblokCommon.configuration.content.currency_symbol}>
                                <input
                                    type="text"
                                    id="order-additional-costs"
                                    onChange={additionalCostsChange}
                                    onFocus={(event) => event.target.select()}
                                    onBlur={() => {
                                        if (!additionalCosts) {
                                            setAdditionalCosts('0');
                                        }
                                    }}
                                    value={additionalCosts}
                                    className={OrderServicesAdditionalCostsStyles.input}
                                    maxLength={6}
                                />
                            </div>
                            <p className={OrderServicesAdditionalCostsStyles.info}>{blok.note}</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default OrderServicesAdditionalCosts;
