import React from 'react';
import { IAccountFullData } from '../../../_shared/interfaces/account-full-data';
import { IOrder } from '../../../_shared/interfaces/order';
import AccountContext from '../../../context/account/account.context';
import LoadingAnimationContext from '../../../context/loading-animation/loading-animation.context';
import { isRichText, replaceInRichText } from '../../../lib/helpers/helpers';
import { getRichTextMarkup, SB_PLACEHOLDER_SIGN_IN, SB_PLACEHOLDER_SIGN_UP, StoryblokCommon } from '../../../lib/storyblok/storyblok';
import { OrderSuccessStoryblok } from '../../../_shared/interfaces/storyblok';

import * as OrderSuccessStyles from './success.module.scss';

const OrderSuccess = ({
    blok,
    customerEmail,
    storyblokCommon,
    currentOrder,
    costEstimateEnabled,
}: {
    blok: OrderSuccessStoryblok;
    customerEmail: string;
    storyblokCommon: StoryblokCommon;
    currentOrder: IOrder;
    costEstimateEnabled: boolean;
}) => {
    const accountContext = React.useContext(AccountContext);
    const { showLoadingAnimation, hideLoadingAnimation } = React.useContext(LoadingAnimationContext);

    const referenceNumber: number = (typeof window !== 'undefined' && history.state?.referenceNumber) ?? 0;

    const onSuccesfulSignUp = () => {
        if (currentOrder) {
            const fullData: IAccountFullData = {
                firstName: currentOrder.customer?.firstName ?? '',
                lastName: currentOrder.customer?.lastName ?? '',
                phoneNumber: currentOrder.customer?.phoneNumber ?? '',
                addresses: [],
                devices: [],
            };

            // addresses
            if (currentOrder.deliveryFromCustomer?.pickupDetails?.address) {
                fullData.addresses.push(currentOrder.deliveryFromCustomer?.pickupDetails?.address);
            }
            if (currentOrder.deliveryToCustomer?.address) {
                fullData.addresses.push(currentOrder.deliveryToCustomer?.address);
            }
            if (currentOrder.invoice?.address) {
                fullData.addresses.push(currentOrder.invoice?.address);
            }

            // devices
            const manufacturer = storyblokCommon.workshopServices.content.manufacturer.find((m) => {
                if (m.name === currentOrder.manufacturer) {
                    return true;
                }
            });

            if (manufacturer) {
                currentOrder.articles?.forEach((a) => {
                    // @ts-ignore
                    const product = manufacturer.products.find((p) => a.product === p.name);
                    if (product) {
                        fullData.devices.push({
                            manufacturer: currentOrder.manufacturer ?? '',
                            productName: product.name,
                            serialNumber: a.serialNumber,
                            softwareVersion: a.softwareVersion,
                            smallestServiceIntervalMonths: parseFloat((product.service_interval ?? 0).toString()),
                            serialNumberRepresentation: '',
                            softwareVersionRepresentation: '',
                            lastServiceDate: new Date().toJSON(),
                        });
                    }
                });
            }
            showLoadingAnimation();
            accountContext.updateAll(fullData).finally(() => {
                hideLoadingAnimation();
            });
        }
    };

    const handlePageClick = (e: MouseEvent): void => {
        const clickedElement = e.target as HTMLElement;
        let linkELement;

        if (clickedElement.tagName.toLowerCase() === 'a') {
            linkELement = clickedElement as HTMLLinkElement;
        } else if (clickedElement.parentElement && clickedElement.parentElement.tagName.toLowerCase() === 'a') {
            linkELement = clickedElement.parentElement as HTMLLinkElement;
        }

        if (linkELement && (linkELement.href.includes(SB_PLACEHOLDER_SIGN_IN) || linkELement.href.includes(SB_PLACEHOLDER_SIGN_UP))) {
            e.preventDefault();
            if (linkELement.href.includes(SB_PLACEHOLDER_SIGN_IN)) {
                accountContext.signIn(storyblokCommon.configuration.content.language, storyblokCommon.configuration.content.theme);
            } else if (linkELement.href.includes(SB_PLACEHOLDER_SIGN_UP)) {
                accountContext.signUp(
                    storyblokCommon.configuration.content.language,
                    storyblokCommon.configuration.content.theme,
                    onSuccesfulSignUp,
                );
            }
        }
    };

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            document.addEventListener('click', handlePageClick);
            return () => {
                document.removeEventListener('click', handlePageClick);
            };
        }
    }, []);

    return (
        <div className={OrderSuccessStyles.success}>
            <div className={OrderSuccessStyles.oval} />
            {costEstimateEnabled && referenceNumber && isRichText(blok.cost_estimate_copytext) ? (
                <div>
                    <h4>{blok.cost_estimate_headline}</h4>
                    <p
                        dangerouslySetInnerHTML={getRichTextMarkup(
                            replaceInRichText(
                                replaceInRichText(blok.cost_estimate_copytext, '{reference_number}', referenceNumber.toString()),
                                '{customer_email}',
                                customerEmail,
                            ),
                        )}
                    />
                </div>
            ) : (
                <div>
                    <h4>{blok.headline}</h4>
                    <p dangerouslySetInnerHTML={getRichTextMarkup(replaceInRichText(blok.copytext, '{customer_email}', customerEmail))} />
                    {isRichText(blok.copytext_guest) && !accountContext.account && (
                        <div dangerouslySetInnerHTML={getRichTextMarkup(blok.copytext_guest)} style={{ paddingTop: '24px' }} />
                    )}
                </div>
            )}
        </div>
    );
};

export default OrderSuccess;
