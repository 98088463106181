import React, { FC, useContext } from 'react';
import HsButton from '../../_common/hs-button/hs-button';
import * as styles from './additional-services.module.scss';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';
import AdditionalServicesCardHeader from './additional-services-header';
import AdditionalServicesCardDescription from './additional-services-description';
import AdditionalServicesList from './additional-services-list';
import { useAdditionalServices } from './additional-services-context';
import NextButton from './additional-services-next-button';
import { OrderContext } from '../../../context/order/order.context';
import { getAdditionalServiceProducts } from '../../../lib/order/order';

type Props = {
    headline: string;
    nextButtonText: string;
    optionsHeadline: string;
    copytext: any;
    storyblokCommon: StoryblokCommon;
};

const AdditionalServicesCard: FC<Props> = ({
    storyblokCommon,
    headline = '',
    copytext = {},
    nextButtonText = '',
    optionsHeadline = '',
}) => {
    const { order } = useContext(OrderContext);
    const additionalServicesProducts = getAdditionalServiceProducts(order, storyblokCommon, storyblokCommon.workshopServices.content);
    const { productName, serviceName } = additionalServicesProducts[0] ?? {};
    const { closeAdditionalServicesMenu } = useAdditionalServices();
    const themeName = storyblokCommon.configuration.content.theme === 'dr' ? 'dr' : 'hs';
    return (
        <div className={`${styles.card} ${styles[themeName]}`}>
            <HsButton
                appearance="primary"
                icon={'back'}
                onClick={closeAdditionalServicesMenu}
                size="small"
                className={styles.closeButton}
                storyblokCommon={storyblokCommon}
            />
            <AdditionalServicesCardHeader title={headline} />
            <AdditionalServicesCardDescription copytext={copytext} serviceName={serviceName} productName={productName} />
            <div className={styles.additionalServices}>
                <p>{optionsHeadline}</p>

                {additionalServicesProducts.map(({ serviceId, additionalServices }) => (
                    <AdditionalServicesList
                        key={serviceId}
                        services={additionalServices}
                        productName={productName}
                        storyblokCommon={storyblokCommon}
                    />
                ))}
            </div>
            <NextButton storyblokCommon={storyblokCommon} buttonName={nextButtonText} />
        </div>
    );
};

export default AdditionalServicesCard;
