import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { getRichTextMarkup, StoryblokCommon } from '../../../lib/storyblok/storyblok';
import { AdditionalService } from '../../../_shared/interfaces/order';
import * as FormStylesDr from '../../../_scss/modules/_forms.dr.module.scss';
import * as FormStylesHs from '../../../_scss/modules/_forms.hs.module.scss';
import * as styles from './additional-services.module.scss';
import { OrderContext } from '../../../context/order/order.context';
import { IArticle } from '../../../_shared/interfaces/article';
type Props = {
    service: AdditionalService;
    storyblokCommon: StoryblokCommon;
    productName: string;
};

const AdditionalServiceOption: FC<Props> = ({
    service = {} as AdditionalService,
    storyblokCommon: { configuration: { content: { theme = 'hs', currency = '' } = {} } = {} } = {},
    productName,
}) => {
    const intl = useIntl();
    const { name = '', price = 0, id = '', copytext = { content: [{}] } } = service;
    const { order, updateOrder } = useContext(OrderContext);
    const currrentTheme = theme === 'dr' ? FormStylesDr : FormStylesHs;
    const { additionalServices = [], ...rest } = order.articles?.find((a) => a.product === productName) ?? ({} as IArticle);

    const handleChange = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
        const otherArticles = order.articles?.filter((a) => a.product !== productName) ?? [];
        const newAdditionalServices = checked ? [service] : additionalServices.filter((s) => s.id !== id);
        updateOrder({
            ...order,
            articles: [...otherArticles, { additionalServices: newAdditionalServices, ...rest }] as IArticle[] | undefined,
        });
    };
    const selected = additionalServices.some((s) => s.id === id && s.name === name);
    const formattedPrice = intl.formatNumber(price, {
        style: 'currency',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        currency,
    });
    const html = getRichTextMarkup(copytext);
    return (
        <li className={styles.additionalServiceItem}>
            <input type="checkbox" checked={selected} id={id} onChange={handleChange} />
            <label className={currrentTheme.checkboxLabel} htmlFor={service.id} />
            <h6 className={styles.additionalServiceItemName}>
                {name} ({formattedPrice})
            </h6>
            <div className={styles.additionalServiceItemDescription} dangerouslySetInnerHTML={html} />
        </li>
    );
};

export default AdditionalServiceOption;
