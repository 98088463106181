// extracted by mini-css-extract-plugin
export var addressBox = "payment-dr-module--address-box--61d53";
export var addressListPicker = "payment-dr-module--address-list-picker--56dbb";
export var button = "payment-dr-module--button--dd911";
export var buttonWrapper = "payment-dr-module--button-wrapper--284cf";
export var formFields = "payment-dr-module--form-fields--69b65";
export var icon = "payment-dr-module--icon--39b31";
export var orderPayment = "payment-dr-module--order-payment--ad326";
export var paymentMethodBillIcon = "payment-dr-module--payment-method-bill-icon--fd53b";
export var paymentMethodBox = "payment-dr-module--payment-method-box--366fb";
export var paymentMethodListPicker = "payment-dr-module--payment-method-list-picker--67039";
export var paymentRadioBox = "payment-dr-module--payment-radio-box--a8366";
export var underline = "payment-dr-module--underline--59c68";