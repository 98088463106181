import React, { createContext, ReactElement, FC, useContext, useState } from 'react';
type State = {
    visible: boolean;
};

export type AdditionalServicesContextType = {
    state: State;
    showAdditionalServicesMenu: () => void;
    closeAdditionalServicesMenu: () => void;
};

export const AdditionalServicesContext = createContext<AdditionalServicesContextType>({} as AdditionalServicesContextType);

type Props = {
    children: ReactElement<any> | ReactElement<any>[];
};

export const AdditionalServicesProvider: FC<Props> = ({ children }) => {
    const [visible, setVisibility] = useState<boolean>(false);
    const value: AdditionalServicesContextType = {
        state: {
            visible,
        } as State,
        showAdditionalServicesMenu: () => setVisibility(true),
        closeAdditionalServicesMenu: () => setVisibility(false),
    };

    return <AdditionalServicesContext.Provider value={value}>{children}</AdditionalServicesContext.Provider>;
};

export const useAdditionalServices = () => useContext(AdditionalServicesContext);
