import { navigate } from 'gatsby';
import React, { FC } from 'react';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';
import * as AdditionalServicesStyles from './additional-services.module.scss';
import { useAdditionalServices } from './additional-services-context';
import * as HsButtonStylesHs from '../../_common/hs-button/hs-button.hs.module.scss';
import * as HsButtonStylesDr from '../../_common/hs-button/hs-button.dr.module.scss';

type Props = {
    storyblokCommon: StoryblokCommon;
    buttonName: string;
};

const AdditionalServicesNextButton: FC<Props> = ({ storyblokCommon, buttonName }) => {
    const HsButtonStyles = storyblokCommon.configuration.content.theme === 'dr' ? HsButtonStylesDr : HsButtonStylesHs;
    const { closeAdditionalServicesMenu } = useAdditionalServices();
    const handleClick = () => {
        closeAdditionalServicesMenu();
        navigate(self.location.pathname, { state: { step: 'delivery' } });
    };
    return (
        <div className={AdditionalServicesStyles.buttonWrapperTwoButtons}>
            <button className={`${HsButtonStyles.hsButton} ${HsButtonStyles.primary}`} onClick={handleClick}>
                {buttonName}
            </button>
        </div>
    );
};
export default AdditionalServicesNextButton;
