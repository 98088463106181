import '../../../_scss/_list-animation.scss';

import { navigate } from 'gatsby';
import _ from 'lodash';
import React from 'react';
import FocusTrap from 'react-focus-trap';
import { useIntl } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import SbEditable from 'storyblok-react';

import * as FormStylesDr from '../../../_scss/modules/_forms.dr.module.scss';
import * as FormStylesHs from '../../../_scss/modules/_forms.hs.module.scss';
import * as ModalStyles from '../../../_scss/modules/_modal.module.scss';
import { IArticle } from '../../../_shared/interfaces/article';
import { IDevice } from '../../../_shared/interfaces/device';
import { IOrder } from '../../../_shared/interfaces/order';
import {
    OrderServicesAdditionalCostsStoryblok,
    OrderServicesArticleStoryblok,
    OrderServicesStoryblok,
} from '../../../_shared/interfaces/storyblok';
import { AccountContext } from '../../../context/account/account.context';
import LoadingAnimationContext from '../../../context/loading-animation/loading-animation.context';
import { OrderContext } from '../../../context/order/order.context';
import { getOptionByLabel, getProductOptions } from '../../../lib/article/article';
import { isGlobalDataSource, isRichText, scrollToFirstError } from '../../../lib/helpers/helpers';
import { getLinkFragment } from '../../../lib/link-fragment/link-fragment';
import {
    canNotSubmitOrder,
    getAdditionalServiceProducts,
    getTestArticles,
    handleAzureAdB2cLinks,
    orderArticlesIdentical,
} from '../../../lib/order/order';
import { getRichTextMarkup, StoryblokCommon } from '../../../lib/storyblok/storyblok';
import { trackEvent } from '../../../lib/track/track';
import HsButtonSimple from '../../_common/hs-button-simple/hs-button-simple';
import HsButton from '../../_common/hs-button/hs-button';
import HubspotFormInclude from '../../_common/hubspot-form/hubspot-form-include';
import ManufacturerSelect from '../../_common/manufacturer-select/manufacturer-select';
import { useAdditionalServices } from '../additional-services/additional-services-context';
import OrderServicesAdditionalCosts from './additional-costs/additional-costs';
import * as OrderServicesAdditionalCostsStylesDr from './additional-costs/additional-costs.dr.module.scss';
import * as OrderServicesAdditionalCostsStylesHs from './additional-costs/additional-costs.hs.module.scss';
import Article from './services-article/services-article';
import * as OrderServicesStylesDr from './services.dr.module.scss';
import * as OrderServicesStylesHs from './services.hs.module.scss';

export interface IProductOptions {
    [manufacturer: string]: IProductGroup[];
}

export interface IProductGroup {
    label: string;
    options: IProduct[];
}

export interface IProduct {
    deviceId?: string;
    label: string;
    value: string;
    services: IServiceOption[];
    serialNumber?: string;
    softwareVersion?: string;
    isMyDevice?: boolean;
}

export interface IServiceOption {
    label: string;
    value: string;
    additionalCosts: boolean;
}

const OrderServices = ({
    blok,
    blokAdditionalCosts,
    blokArticle,
    storyblokCommon,
    costEstimateEnabled,
}: {
    blok: OrderServicesStoryblok;
    blokAdditionalCosts: OrderServicesAdditionalCostsStoryblok;
    blokArticle: OrderServicesArticleStoryblok;
    storyblokCommon: StoryblokCommon;
    costEstimateEnabled: boolean;
}) => {
    const intl = useIntl();
    const accountContext = React.useContext(AccountContext);
    const loadingAnimationContext = React.useContext(LoadingAnimationContext);
    const { order, updateOrder, isRestored } = React.useContext(OrderContext);
    const [productOptions, setProductOptions] = React.useState<IProductOptions | undefined>(undefined);
    const [showAdditionalCosts, setShowAdditionalCosts] = React.useState(false);
    const { showAdditionalServicesMenu } = useAdditionalServices();
    const [showErrors, setShowErrors] = React.useState(false);
    const formStylesError = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr.error : FormStylesHs.error;
    const [lastOrderUpdate, setLastOrderUpdate] = React.useState<IOrder | undefined>(undefined);
    const [showTestModal, setShowTestModal] = React.useState(false);
    const [testItems, setTestItems] = React.useState<IArticle[]>([]);
    const [globalManufacturers, setGlobalManufacturers] = React.useState([]);
    const OrderServicesStyles = storyblokCommon.configuration.content.theme === 'dr' ? OrderServicesStylesDr : OrderServicesStylesHs;

    const addArticle = (): void => {
        setShowErrors(false);
        const { articles = [] } = order;
        const newArticle: IArticle = {
            product: '',
            service: '',
            notes: '',
            serviceIntervalMonths: 0,
            additionalServices: [],
        };

        updateOrder({ ...order, articles: [...articles.map((a) => ({ ...a, additionalServices: [] })), newArticle] });
        const elem = document.querySelector('.' + OrderServicesStyles.header + '+ ul > li:last-child');
        if (elem) {
            elem.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    };

    const removeArticle = (index: number): void => {
        if (order.articles && order.articles.length > 1) {
            const removeProduct = order.articles[index]?.product;
            if (removeProduct) {
                trackEvent('serviceorder.productRemove', {
                    serviceorder: { type: 'workshop_service', name: '1:ServiceSelection' },
                    serviceorderField: { name: 'ShoppingCart', value: { product: removeProduct } },
                });
            }

            updateOrder({ ...order, articles: order.articles.filter((n, i) => index !== i) });
        }
    };

    const changeManufacturer = (o: IOrder): void => {
        if (o?.manufacturer) {
            trackEvent('serviceorder.field_changed', {
                serviceorder: { type: 'workshop_service', name: '1:ServiceSelection' },
                serviceorderField: { name: 'Manufacturer', value: { manufacturer: o?.manufacturer } },
            });
        }
        updateOrder({ ...o, articles: [] });
    };

    const checkForAdditionalCosts = (): void => {
        let show = false;
        if (order.manufacturer && order.articles && productOptions) {
            outerBlock: for (const article of order.articles) {
                for (const productGroup of productOptions[order.manufacturer]) {
                    for (const product of productGroup.options) {
                        for (const service of product.services) {
                            if (service.value === article.service && service.additionalCosts) {
                                show = true;
                                break outerBlock;
                            }
                        }
                    }
                }
            }
            setShowAdditionalCosts(show);

            if (!show) {
                updateOrder({ additionalCosts: '' });
            }
        }
    };

    const submitPage = (): void => {
        if (canNotSubmitOrder(order, storyblokCommon)) {
            setShowErrors(true);
            scrollToFirstError(formStylesError);
        } else {
            const currentTestItems = getTestArticles(order, storyblokCommon);

            if (currentTestItems.length) {
                setTestItems(currentTestItems);
                setShowTestModal(true);
            } else if (order.articles?.length === 1) {
                if (getAdditionalServiceProducts(order, storyblokCommon, storyblokCommon.workshopServices.content).length) {
                    showAdditionalServicesMenu();
                } else {
                    navigate(self.location.pathname, { state: { step: 'delivery' } });
                }
            } else if (order.articles && order.articles.length > 1) {
                navigate(self.location.pathname, { state: { step: 'delivery' } });
            }
        }
    };

    const mergeProductOptionsWithMyDevices = (
        originalProductOptions: { [manufacturer: string]: IProductGroup[] },
        myDevices: IDevice[],
        manufacturer: string,
    ): { [manufacturer: string]: IProductGroup[] } => {
        const manufacturerData = storyblokCommon.workshopServices.content.manufacturer.find((m) => m.name === manufacturer) ?? {
            products: [],
        };
        myDevices = myDevices
            .filter((d) => d.id !== undefined) // only devices with ID (should never apply)
            .filter((d) => d.manufacturer === manufacturer) // only devices for selected manufacturer
            .filter((d) => manufacturerData.products.find((p) => p.name === d.productName)); // only valid devices
        if (myDevices.length) {
            const myDevicesGroup: IProductGroup = {
                label: blokArticle.product_category_my_devices,
                options: myDevices.map((m) => {
                    let text = blokArticle.product_my_device_pattern;
                    if (m.serialNumber) {
                        if (m.inventoryNumber) {
                            text = blokArticle.product_my_device_pattern_full;
                        } else {
                            text = blokArticle.product_my_device_pattern_sn;
                        }
                    } else if (m.inventoryNumber) {
                        text = blokArticle.product_my_device_pattern_in;
                    }

                    // copy services from original product
                    const originalProduct = getOptionByLabel(originalProductOptions[manufacturer], m.productName);
                    // const enrichedProductName = intl.messages['sections.order.services.article.product.' + textId]
                    const enrichedProductName = text
                        .toString()
                        .replace('<PRODUCT>', m.productName)
                        .replace('<SERIAL_NUMBER>', m.serialNumber ?? '')
                        .replace('<INVENTORY_NUMBER>', m.inventoryNumber ?? '');
                    return {
                        label: enrichedProductName,
                        value: m.productName,
                        services: originalProduct ? originalProduct.services : [],
                        serialNumber: m.serialNumber,
                        isMyDevice: true,
                        deviceId: m.id,
                    };
                }),
            };
            // add devices to standard options
            originalProductOptions[manufacturer].unshift(myDevicesGroup);
        }
        return originalProductOptions;
    };

    const setDefaultManufacturer = (): void => {
        let defaultManufacturer = '';
        if (storyblokCommon.workshopServices.content.manufacturer.length === 1) {
            defaultManufacturer = storyblokCommon.workshopServices.content.manufacturer[0].name;
        }
        if (defaultManufacturer && order.manufacturer !== defaultManufacturer) {
            updateOrder({ manufacturer: defaultManufacturer });
        }
    };

    const handlePageClick = (e: MouseEvent): void => {
        handleAzureAdB2cLinks(e, accountContext, storyblokCommon);
    };

    const trackServiceStep1 = (): void => {
        document.removeEventListener('keydown', trackServiceStep1, false);
        const selects = document.querySelectorAll('main select');
        selects.forEach((select) => {
            select.removeEventListener('change', trackServiceStep1, false);
        });
        trackEvent('serviceorder.open', { serviceorder: { type: 'workshop_service', name: '1:ServiceSelection' } });
    };

    const closeTestItemsOverlay = (): void => {
        setShowTestModal(false);
    };

    React.useEffect(() => {
        if (showErrors) {
            scrollToFirstError(formStylesError);
        }
    }, [showErrors]);

    React.useEffect(() => {
        setShowErrors(false);
    }, [order.manufacturer]);

    React.useEffect(() => {
        let options = _.cloneDeep(getProductOptions(storyblokCommon.workshopServices.content.manufacturer, storyblokCommon, intl));

        if (accountContext.devices && accountContext.devices.length && order.manufacturer) {
            options = mergeProductOptionsWithMyDevices(options, accountContext.devices, order.manufacturer);
        }

        setProductOptions(options);
    }, [order.manufacturer, accountContext.devices]);

    React.useEffect(() => {
        if (accountContext.account && !accountContext.devices) {
            loadingAnimationContext.showLoadingAnimation();
            accountContext.devicesRequest().finally(() => {
                loadingAnimationContext.hideLoadingAnimation();
            });
        }
    }, [accountContext.account]);

    React.useEffect(() => {
        if (isRestored) {
            setDefaultManufacturer();
        }
    }, [isRestored]);

    React.useEffect(() => {
        if (showTestModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    }, [showTestModal]);

    React.useEffect(() => {
        if (lastOrderUpdate && lastOrderUpdate.articles && order.articles) {
            // only if product/service combination or additionalServices has changed. Otherwise, there will be an endless loop
            if (!orderArticlesIdentical(lastOrderUpdate, order)) {
                checkForAdditionalCosts();
            }
        }

        setLastOrderUpdate(_.cloneDeep(order));
    }, [order]);

    React.useEffect(() => {
        if (accountContext.account && !accountContext.devices) {
            loadingAnimationContext.showLoadingAnimation();
            accountContext.devicesRequest().finally(() => {
                loadingAnimationContext.hideLoadingAnimation();
            });
        }

        if (typeof window !== 'undefined') {
            document.addEventListener('click', handlePageClick);
            document.addEventListener('keydown', trackServiceStep1, false);
            const selects = document.querySelectorAll('main select');
            selects.forEach((select) => {
                select.addEventListener('change', trackServiceStep1, false);
            });

            return () => {
                document.removeEventListener('click', handlePageClick);
                document.removeEventListener('keydown', trackServiceStep1, false);
                selects.forEach((select) => {
                    select.removeEventListener('change', trackServiceStep1, false);
                });
            };
        }
    }, []);

    React.useEffect(() => {
        const filteredManufacturers = storyblokCommon.workshopServices.content.manufacturer.filter((m) => {
            if (isGlobalDataSource(storyblokCommon) && m.component.includes('global')) {
                return m;
            } else if (!isGlobalDataSource(storyblokCommon) && !m.component.includes('global')) {
                return m;
            }
        });

        setGlobalManufacturers(filteredManufacturers);
    }, []);

    return (
        <div className={OrderServicesStyles.orderServices}>
            <div className={OrderServicesStyles.manufacturer}>
                <SbEditable content={blok.manufacturer[0]}>
                    <ManufacturerSelect
                        blok={{
                            headline: blok.manufacturer[0].headline,
                            error: blok.manufacturer[0].error,
                            component: 'manufacturer_select',
                            _uid: '',
                        }}
                        manufacturers={globalManufacturers.map((m) => {
                            return { key: m.name, value: m.name };
                        })}
                        showErrors={showErrors}
                        initialValue={order.manufacturer}
                        onChange={(m) => changeManufacturer({ manufacturer: m })}
                        headlineSmall={false}
                        storyblokCommon={storyblokCommon}
                    />
                </SbEditable>
            </div>

            <div className={OrderServicesStyles.articles}>
                <div className={OrderServicesStyles.header}>
                    <h5>{blok.headline}</h5>
                    {isRichText(blok.copytext_guest) && !accountContext.account && (
                        <div dangerouslySetInnerHTML={getRichTextMarkup(blok.copytext_guest)} style={{ paddingTop: '24px' }} />
                    )}
                    {costEstimateEnabled && (
                        <div className={OrderServicesStyles.costEstimationWrapper}>
                            <i className={OrderServicesStyles.icon} />

                            {isRichText(blok.cost_estimation_copytext) && accountContext.account && (
                                <div
                                    dangerouslySetInnerHTML={getRichTextMarkup(blok.cost_estimation_copytext)}
                                    style={{ paddingLeft: '24px' }}
                                />
                            )}
                            {isRichText(blok.cost_estimation_copytext_guest) && !accountContext.account && (
                                <div
                                    dangerouslySetInnerHTML={getRichTextMarkup(blok.cost_estimation_copytext_guest)}
                                    style={{ paddingLeft: '24px' }}
                                />
                            )}
                        </div>
                    )}
                </div>

                <TransitionGroup className="product-list" component="ul">
                    {(order.articles ? order.articles : []).map((article: IArticle, index: number) => {
                        return (
                            <CSSTransition key={index} timeout={500} classNames="item">
                                <li>
                                    {index > 0 && (
                                        <HsButtonSimple
                                            icon="delete"
                                            className={OrderServicesStyles.deleteIcon}
                                            onClick={() => removeArticle(index)}
                                            storyblokCommon={storyblokCommon}
                                        >
                                            {blok.delete_article}
                                        </HsButtonSimple>
                                    )}

                                    {productOptions && (
                                        <SbEditable content={blokArticle}>
                                            <Article
                                                blok={blokArticle}
                                                storyblokCommon={storyblokCommon}
                                                manufacturer={order.manufacturer ?? undefined}
                                                productOptions={productOptions}
                                                articleIndex={index}
                                                showErrors={showErrors}
                                                initialValues={article}
                                            />
                                        </SbEditable>
                                    )}
                                </li>
                            </CSSTransition>
                        );
                    })}
                </TransitionGroup>

                <div className={OrderServicesStyles.buttonWrapper}>
                    <HsButton
                        appearance="secondary"
                        icon="plus"
                        onClick={() => addArticle()}
                        disabled={!order.manufacturer}
                        storyblokCommon={storyblokCommon}
                    >
                        {blok.add_article}
                    </HsButton>
                </div>
            </div>

            {showAdditionalCosts && (
                <div>
                    <SbEditable content={blokAdditionalCosts}>
                        <OrderServicesAdditionalCosts blok={blokAdditionalCosts} storyblokCommon={storyblokCommon} />
                    </SbEditable>
                </div>
            )}

            <div className={OrderServicesStyles.bottomWrapper}>
                <div>
                    <p>{blok.mandatory_legend}</p>
                </div>

                <div dangerouslySetInnerHTML={getRichTextMarkup(blok.copytext)} />
                <div>
                    <HsButton appearance="primary" onClick={submitPage} storyblokCommon={storyblokCommon}>
                        {blok.submit_button}
                    </HsButton>
                </div>
            </div>

            {showTestModal && (
                <FocusTrap>
                    <div className={ModalStyles.modal}>
                        <div className={ModalStyles.inner}>
                            <div className={ModalStyles.content}>
                                <HsButton
                                    appearance="secondary"
                                    icon={'close'}
                                    onClick={closeTestItemsOverlay}
                                    size="small"
                                    className={ModalStyles.close}
                                    storyblokCommon={storyblokCommon}
                                />
                                <div className={OrderServicesStyles.testOverlay}>
                                    <h2>{blok.test_overlay_headline}</h2>
                                    <p dangerouslySetInnerHTML={getRichTextMarkup(blok.test_overlay_copytext)} />
                                    <br />
                                    <h5>{blok.test_overlay_subheadline}</h5>
                                    <ul>
                                        {testItems.map((testItem, index) => {
                                            return (
                                                <li key={index}>
                                                    <p>
                                                        {testItem.product}, {testItem.serviceName}
                                                    </p>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    {blok.test_overlay_hubspot_region &&
                                        blok.test_overlay_hubspot_formId &&
                                        blok.test_overlay_hubspot_portalID && (
                                            // <div style={{ background: 'red', height: '1000px' }}>test block</div>
                                            <HubspotFormInclude
                                                region={blok.test_overlay_hubspot_region}
                                                formId={blok.test_overlay_hubspot_formId}
                                                portalId={blok.test_overlay_hubspot_portalID}
                                                storyblokCommon={storyblokCommon}
                                            />
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            )}
        </div>
    );
};

export default OrderServices;
