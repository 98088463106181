import React, { createContext, FC, ReactElement, useContext } from 'react';
import * as hs from './shopping-cart.hs.module.scss';
import * as dr from './shopping-cart.dr.module.scss';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';
import { OrderShoppingCartStoryblok } from '../../../_shared/interfaces/storyblok';
import { ShoppingCartDetails } from '../../../_shared/interfaces/order';

const hsStyles = { ...hs };
const drStyles = { ...dr };

type ShoppingCartContext = {
    styles: any;
    flaggedArticleIndex: number;
    setFlaggedArticle: (art: number) => void;
} & Props;

type Props = {
    shoppingCartStoryblok: OrderShoppingCartStoryblok;
    storyblokCommon: StoryblokCommon;
    children: ReactElement<any> | ReactElement<any>[];
    cartDetails: ShoppingCartDetails;
};

const Context = createContext<ShoppingCartContext>({} as ShoppingCartContext);

export const ShoppingCartContextProvider: FC<Props> = ({ children, storyblokCommon, shoppingCartStoryblok, cartDetails }) => {
    const styles = storyblokCommon.configuration.content.theme === 'dr' ? drStyles : hsStyles;
    const [flaggedArticleIndex, setFlaggedArticle] = React.useState<number>(-1);

    return (
        <Context.Provider
            value={
                {
                    styles,
                    shoppingCartStoryblok,
                    storyblokCommon,
                    cartDetails,
                    flaggedArticleIndex,
                    setFlaggedArticle,
                } as ShoppingCartContext
            }
        >
            {children}
        </Context.Provider>
    );
};

export const useShoppingCartStyles = () => {
    const { styles } = useContext(Context);
    return styles;
};

export const useShoppingCartStoryBlok = () => {
    const { shoppingCartStoryblok } = useContext(Context);
    return shoppingCartStoryblok;
};

export const useShoppingCartStoryBlokCommon = () => {
    const { storyblokCommon } = useContext(Context);
    return storyblokCommon;
};

export const useShoppingCartDetails = () => {
    const { cartDetails } = useContext(Context);
    return cartDetails ?? ({} as ShoppingCartDetails);
};

export const useShoppingCartEvents = () => {
    const { setFlaggedArticle } = useContext(Context);
    return { setFlaggedArticle };
};

export const useFlaggedShoppingCartArticle = () => {
    const { flaggedArticleIndex } = useContext(Context);
    return flaggedArticleIndex;
};
