import React from 'react';
import { navigate } from 'gatsby';
import HsButton from '../../_common/hs-button/hs-button';
import OrderDeliveryFromCustomer from './from-customer/from-customer';
import OrderDeliveryToCustomer from './to-customer/to-customer';
import { OrderContext } from '../../../context/order/order.context';
import { deliveryErrorCheck, scrollToFirstError } from '../../../lib/helpers/helpers';
import { AccountContext } from '../../../context/account/account.context';
import LoadingAnimationContext from '../../../context/loading-animation/loading-animation.context';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';
import { OrderDeliveryFromCustomerStoryblok, OrderDeliveryToCustomerStoryblok } from '../../../_shared/interfaces/storyblok';

import * as OrderDeliveryStyles from './delivery.module.scss';

import * as FormStylesHs from '../../../_scss/modules/_forms.hs.module.scss';
import * as FormStylesDr from '../../../_scss/modules/_forms.dr.module.scss';
import SbEditable from 'storyblok-react';
import { trackEvent } from '../../../lib/track/track';

const OrderDelivery = ({
    storyblokCommon,
    blokFrom,
    blokTo,
}: {
    storyblokCommon: StoryblokCommon;
    blokFrom: OrderDeliveryFromCustomerStoryblok;
    blokTo: OrderDeliveryToCustomerStoryblok;
}) => {
    const { order } = React.useContext(OrderContext);
    const accountContext = React.useContext(AccountContext);
    const loadingAnimationContext = React.useContext(LoadingAnimationContext);
    const [showErrors, setShowErrors] = React.useState(false);
    const formStylesError = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr.error : FormStylesHs.error;

    const navigateBack = () => {
        navigate(self.location.pathname, { state: { step: 'services' } });
    };

    const submitPage = () => {
        const errors = deliveryErrorCheck(order);
        if (!errors.deliveryFromError && !errors.deliveryToError) {
            navigate(self.location.pathname, { state: { step: 'payment' } });
        } else {
            setShowErrors(true);
            scrollToFirstError(formStylesError);
        }
    };

    React.useEffect(() => {
        trackEvent('serviceorder.step', { serviceorder: { type: 'workshop_service', name: '2:Shipment' } });

        if (accountContext.account && !accountContext.addresses) {
            loadingAnimationContext.showLoadingAnimation();
            accountContext.addressesRequest().finally(() => {
                loadingAnimationContext.hideLoadingAnimation();
            });
        }
    }, [accountContext.account]);

    return (
        <div>
            <div className={OrderDeliveryStyles.fromCustomerBox}>
                <SbEditable content={blokFrom}>
                    <OrderDeliveryFromCustomer showErrors={showErrors} storyblokCommon={storyblokCommon} blok={blokFrom} />
                </SbEditable>
            </div>

            <div>
                <SbEditable content={blokTo}>
                    <OrderDeliveryToCustomer showErrors={showErrors} storyblokCommon={storyblokCommon} blok={blokTo} />
                </SbEditable>
            </div>

            <div className={OrderDeliveryStyles.buttonWrapper}>
                <div>
                    <HsButton appearance="secondary" onClick={navigateBack} storyblokCommon={storyblokCommon}>
                        {storyblokCommon.configuration.content.order_button_back}
                    </HsButton>
                </div>
                <div>
                    <HsButton onClick={submitPage} storyblokCommon={storyblokCommon}>
                        {storyblokCommon.configuration.content.order_button_next}
                    </HsButton>
                </div>
            </div>
        </div>
    );
};

export default OrderDelivery;
