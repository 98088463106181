import React from 'react';
import { OrderContext, IOrderContext } from '../../../context/order/order.context';
import { AccountContext } from '../../../context/account/account.context';
import { handleAzureAdB2cLinks } from '../../../lib/order/order';
import HsButton from '../../_common/hs-button/hs-button';
import { IAddress } from '../../../_shared/interfaces/address';
import AddressListPicker from '../../_common/address-list-picker/address-list-picker';
import AddressForm from '../../_common/address-form/address-form';
import { defaultAddress, isAddressValid, scrollToFirstError, paymentErrorCheck, isRichText } from '../../../lib/helpers/helpers';
import LoadingAnimationContext from '../../../context/loading-animation/loading-animation.context';
import { PaymentType } from '../../../_shared/enums/payment-type';
import { getRichTextMarkup, StoryblokCommon } from '../../../lib/storyblok/storyblok';
import { OrderPaymentStoryblok } from '../../../_shared/interfaces/storyblok';
import { navigate } from 'gatsby';

import * as OrderPaymentStylesHs from './payment.hs.module.scss';
import * as OrderPaymentStylesDr from './payment.dr.module.scss';

import * as FormStylesHs from '../../../_scss/modules/_forms.hs.module.scss';
import * as FormStylesDr from '../../../_scss/modules/_forms.dr.module.scss';
import { trackEvent } from '../../../lib/track/track';

const OrderPayment = ({ blok, storyblokCommon }: { blok: OrderPaymentStoryblok; storyblokCommon: StoryblokCommon }) => {
    const { order, isRestored, updateOrder } = React.useContext<IOrderContext>(OrderContext);
    const accountContext = React.useContext(AccountContext);
    const loadingAnimationContext = React.useContext(LoadingAnimationContext);
    const [initialAddress, setInitialAddress] = React.useState<IAddress | undefined>(undefined);
    const [showErrors, setShowErrors] = React.useState(false);

    const OrderPaymentStyles = storyblokCommon.configuration.content.theme === 'dr' ? OrderPaymentStylesDr : OrderPaymentStylesHs;
    const FormStyles = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr : FormStylesHs;
    const formStylesError = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr.error : FormStylesHs.error;

    const setInitialValues = (): void => {
        if (!order.payment) {
            updateOrder({
                payment: { type: PaymentType.Invoice },
            });
        }
        const address: IAddress = order.invoice?.address ?? defaultAddress;
        setInitialAddress(address);
    };

    const getAddress = (addressId: string): IAddress | undefined => {
        if (accountContext.addresses) {
            let result: IAddress = defaultAddress;
            accountContext.addresses.forEach((a: IAddress) => {
                if (a.id === addressId) {
                    result = a;
                }
            });
            return result;
        } else {
            return;
        }
    };

    const pickAddress = (addressId: string): void => {
        const address = getAddress(addressId);
        if (address) {
            updateOrder({
                invoice: { ...order.invoice, address },
            });
        }
    };

    const enterAddress = (address: IAddress): void => {
        updateOrder({
            invoice: { ...order.invoice, address },
        });
    };

    const copyAdress = (): void => {
        if (isAddressValid(order.deliveryToCustomer?.address)) {
            setInitialAddress({ ...(order.deliveryToCustomer?.address ?? defaultAddress) });
            enterAddress(order.deliveryToCustomer?.address ?? defaultAddress);
        }
    };

    const submitPage = (): void => {
        if (paymentErrorCheck(order)) {
            setShowErrors(true);
            scrollToFirstError(formStylesError);
        } else {
            navigate(self.location.pathname, { state: { step: 'overview' } });
        }
    };

    const navigateBack = (): void => {
        navigate(self.location.pathname, { state: { step: 'delivery' } });
    };

    const handlePageClick = (e: MouseEvent): void => {
        handleAzureAdB2cLinks(e, accountContext, storyblokCommon);
    };

    React.useEffect(() => {
        if (isRestored) {
            setInitialValues();
        }
    }, [isRestored]);

    React.useEffect(() => {
        trackEvent('serviceorder.step', { serviceorder: { type: 'workshop_service', name: '3:Payment Data' } });

        if (accountContext.account && !accountContext.addresses) {
            loadingAnimationContext.showLoadingAnimation();
            accountContext.addressesRequest().finally(() => {
                loadingAnimationContext.hideLoadingAnimation();
            });
        }

        if (typeof window !== 'undefined') {
            document.addEventListener('click', handlePageClick);
            return () => {
                document.removeEventListener('click', handlePageClick);
            };
        }
    }, []);

    return (
        <>
            {OrderPaymentStyles && FormStyles && isRestored && (
                <div className={OrderPaymentStyles.orderPayment}>
                    <div className={OrderPaymentStyles.icon} />
                    <h5>{blok.headline}</h5>
                    <p>
                        {accountContext.accountData ? <>{blok.copytext}</> : <>{blok.copytext_guest}</>}
                        {isRichText(blok.copytext_guest_additional) && !accountContext.account && (
                            <div
                                dangerouslySetInnerHTML={getRichTextMarkup(blok.copytext_guest_additional)}
                                style={{ paddingTop: '24px' }}
                            />
                        )}
                    </p>

                    <div className={OrderPaymentStyles.formFields}>
                        <div className={FormStyles.formField}>
                            <p>
                                <strong>{blok.address_headline}</strong>
                            </p>

                            <div className={OrderPaymentStyles.addressBox}>
                                {accountContext.account && accountContext.addresses ? (
                                    <>
                                        {initialAddress && (
                                            <div className={OrderPaymentStyles.addressListPicker}>
                                                <AddressListPicker
                                                    selectedId={initialAddress.id}
                                                    onAddressChange={pickAddress}
                                                    storyblokCommon={storyblokCommon}
                                                />
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <HsButton
                                            className={OrderPaymentStyles.button}
                                            appearance="secondary"
                                            onClick={copyAdress}
                                            storyblokCommon={storyblokCommon}
                                        >
                                            {blok.copy_button}
                                        </HsButton>
                                        <>
                                            {initialAddress && (
                                                <AddressForm
                                                    initialAddress={initialAddress}
                                                    onChange={enterAddress}
                                                    showErrors={showErrors}
                                                    storyblokCommon={storyblokCommon}
                                                />
                                            )}
                                        </>
                                    </>
                                )}
                                <div className={OrderPaymentStyles.underline} />
                                <label htmlFor="order-payment-order-number">{blok.order_number_label}</label>
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        updateOrder({
                                            invoice: { ...order.invoice, customerOrderNumber: e.target.value },
                                        })
                                    }
                                    onBlur={(e) =>
                                        updateOrder({
                                            invoice: { ...order.invoice, customerOrderNumber: e.target.value.trim() },
                                        })
                                    }
                                    id="order-payment-order-number"
                                    value={order.invoice?.customerOrderNumber}
                                    className={OrderPaymentStyles.input}
                                    maxLength={40}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={OrderPaymentStyles.paymentMethodBox}>
                        <h5>{blok.payment_method_headline}</h5>
                        {blok.payment_method_copytext && <p>{blok.payment_method_copytext}</p>}

                        <div className={OrderPaymentStyles.paymentMethodListPicker}>
                            <p>
                                <strong>{blok.payment_method_invoice_label}</strong>
                            </p>
                        </div>
                        <p>
                            <small>{blok.payment_method_note}</small>
                        </p>
                    </div>

                    <div className={OrderPaymentStyles.buttonWrapper}>
                        <div>
                            <HsButton appearance="secondary" onClick={navigateBack} storyblokCommon={storyblokCommon}>
                                {storyblokCommon.configuration.content.order_button_back}
                            </HsButton>
                        </div>
                        <div>
                            <HsButton onClick={submitPage} className={OrderPaymentStyles.lastButton} storyblokCommon={storyblokCommon}>
                                {storyblokCommon.configuration.content.order_button_next}
                            </HsButton>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default OrderPayment;
