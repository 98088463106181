import React, { FC, memo } from 'react';
import { useShoppingCartStoryBlok, useShoppingCartStyles, useShoppingCartDetails } from './shopping-cart-context';

const ShoppingCartSubtotal: FC = () => {
    const blok = useShoppingCartStoryBlok();
    const styles = useShoppingCartStyles();
    const { subTotal: { formatted = '' } = {} } = useShoppingCartDetails() ?? {};
    const subtotal = formatted.replace('€', '€   ');
    return (
        <div className={styles.section}>
            <h6 className={`${styles.sectionName} ${styles.subtotalName}`} style={{ margin: 0 }}>
                {blok.subtotal}
            </h6>
            <p className={styles.price}>{subtotal.replace('€', '€   ')}</p>
            <div className={`${styles.vat} ${styles.grey}`}>
                <p>{blok.vat_excluded}</p>
            </div>
        </div>
    );
};
export default ShoppingCartSubtotal;
