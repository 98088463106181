import React, { createContext, FC, ReactElement, useContext } from 'react';
import { OrderStep } from '../../templates/page-order';
import { getCurrentStep } from '../../components/order/navigation/navigation';
import { OrderContext } from './order.context';

const StepContext = createContext<OrderStep>('services');

type Props = {
    children: ReactElement<any> | ReactElement<any>[];
};

export const OrderStepProvider: FC<Props> = ({ children }) => {
    const orderContext = useContext(OrderContext);
    const step: OrderStep = (typeof window !== 'undefined' && history.state?.step) ?? getCurrentStep(orderContext);
    return <StepContext.Provider value={step}>{children}</StepContext.Provider>;
};

export const useOrderStep = () => useContext(StepContext);
