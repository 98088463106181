// extracted by mini-css-extract-plugin
export var addressError = "to-customer-hs-module--address-error--b4fbd";
export var addressForm = "to-customer-hs-module--address-form--245e5";
export var button = "to-customer-hs-module--button--0c114";
export var delimiter = "to-customer-hs-module--delimiter--19acb";
export var deliveryTypesList = "to-customer-hs-module--delivery-types-list--7168c";
export var formField = "to-customer-hs-module--form-field--c65bd";
export var header = "to-customer-hs-module--header--268ce";
export var label = "to-customer-hs-module--label--1ae24";
export var labelHeadline = "to-customer-hs-module--label-headline--66a92";
export var price = "to-customer-hs-module--price--a8cdb";