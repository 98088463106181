// extracted by mini-css-extract-plugin
export var active = "navigation-dr-module--active--2fc79";
export var activeStripe = "navigation-dr-module--active-stripe--fd437";
export var before = "navigation-dr-module--before--5dab3";
export var behind = "navigation-dr-module--behind--00c5a";
export var done = "navigation-dr-module--done--bd117";
export var open = "navigation-dr-module--open--b7689";
export var orderNavigation = "navigation-dr-module--order-navigation--897bf";
export var stripe = "navigation-dr-module--stripe--eb9ab";
export var stripeLeft = "navigation-dr-module--stripe-left--77304";
export var stripeRight = "navigation-dr-module--stripe-right--b3ec7";