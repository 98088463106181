import React, { FC } from 'react';
import { getRichTextMarkup } from '../../../lib/storyblok/storyblok';
import * as styles from './additional-services.module.scss';

type Props = {
    copytext: object;
    productName: string;
    serviceName: string;
};

const AdditionalServicesCardDescription: FC<Props> = ({ copytext = {}, productName = '', serviceName = '' }) => (
    <div className={styles.description}>
        <div className={styles.descriptionCopytext} dangerouslySetInnerHTML={getRichTextMarkup(copytext ?? { content: [] })} />
        <h6 className={styles.productAndServiceName}>
            {productName}, {serviceName}
        </h6>
    </div>
);

export default AdditionalServicesCardDescription;
