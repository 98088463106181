import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { OrderNavigationStoryblok } from '../../../_shared/interfaces/storyblok';
import { IOrderContext, OrderContext, StepState } from '../../../context/order/order.context';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';
import * as OrderNavigationStylesDr from './navigation.dr.module.scss';
import { OrderStep } from '../../../templates/page-order';
import * as OrderNavigationStylesHs from './navigation.hs.module.scss';

export const getCurrentStep = (orderContext: IOrderContext): string => {
    let step = localStorage.getItem('orderStep') ?? 'services';

    if (step === 'cost-estimate') {
        step = 'services';
    } else {
        if (
            step === 'overview' &&
            (orderContext.stepStates.service !== StepState.Done ||
                orderContext.stepStates.delivery !== StepState.Done ||
                orderContext.stepStates.payment !== StepState.Done)
        ) {
            step = 'payment';
        }
        if (
            step === 'payment' &&
            (orderContext.stepStates.service !== StepState.Done || orderContext.stepStates.delivery !== StepState.Done)
        ) {
            step = 'delivery';
        }
        if (step === 'delivery' && orderContext.stepStates.service !== StepState.Done) {
            step = 'services';
        }
    }

    return step;
};
const OrderNavigation = ({
    blok,
    step,
    storyblokCommon,
    isCostEstimate,
}: {
    blok: OrderNavigationStoryblok;
    step: OrderStep;
    storyblokCommon: StoryblokCommon;
    isCostEstimate: boolean;
}) => {
    const orderContext = useContext(OrderContext);
    const OrderNavigationStyles = storyblokCommon.configuration.content.theme === 'dr' ? OrderNavigationStylesDr : OrderNavigationStylesHs;

    localStorage.setItem('orderStep', step);

    const navigationEntries = [
        {
            step: 'services',
            text: blok.services,
            status: orderContext.stepStates.service,
        },

        {
            step: 'delivery',
            text: blok.delivery,
            status: orderContext.stepStates.delivery,
        },

        {
            step: 'payment',
            text: blok.payment,
            status: orderContext.stepStates.payment,
        },

        {
            step: isCostEstimate ? 'cost-estimate' : 'overview',
            text: blok.overview,
            status: orderContext.stepStates.overview,
        },
    ];

    const activeNavigationEntryIndex =
        step === 'error'
            ? navigationEntries.length - 1
            : navigationEntries.findIndex((navigationEntry) => {
                  return navigationEntry.step === step;
              });

    const checkNavigate = (navigateToStep: string): boolean => {
        if (navigateToStep === 'services') {
            return true;
        } else if (navigateToStep === 'delivery') {
            if (orderContext.stepStates.service === StepState.Done) {
                return true;
            }
        } else if (navigateToStep === 'payment') {
            if (orderContext.stepStates.service === StepState.Done && orderContext.stepStates.delivery === StepState.Done) {
                return true;
            }
        } else if (navigateToStep === 'overview') {
            if (
                orderContext.stepStates.service === StepState.Done &&
                orderContext.stepStates.delivery === StepState.Done &&
                orderContext.stepStates.payment === StepState.Done
            ) {
                return true;
            }
        }
        return false;
    };

    // React.useEffect(() => {
    //     console.log(orderContext.stepStates);
    // }, [orderContext]);

    return (
        <>
            {OrderNavigationStyles && (
                <div className={OrderNavigationStyles.orderNavigation}>
                    <h4>{blok.headline}</h4>

                    <ul>
                        {navigationEntries.map((navigationEntry, index) => {
                            return (
                                <li
                                    key={index}
                                    className={[
                                        index === activeNavigationEntryIndex
                                            ? OrderNavigationStyles.active
                                            : navigationEntry.status
                                            ? OrderNavigationStyles.done
                                            : OrderNavigationStyles.open,
                                        index < activeNavigationEntryIndex ? OrderNavigationStyles.before : OrderNavigationStyles.behind,
                                    ].join(' ')}
                                >
                                    <div
                                        onClick={() => {
                                            if (isCostEstimate) {
                                                return false;
                                            }
                                            if (checkNavigate(navigationEntry.step)) {
                                                navigate(self.location.pathname, { state: { step: navigationEntry.step } });
                                            }
                                        }}
                                    >
                                        {index > 0 && (
                                            <span
                                                className={[
                                                    OrderNavigationStyles.stripe,
                                                    OrderNavigationStyles.stripeLeft,
                                                    index <= activeNavigationEntryIndex ? OrderNavigationStyles.activeStripe : '',
                                                ].join(' ')}
                                            />
                                        )}
                                        {index < navigationEntries.length - 1 && (
                                            <span
                                                className={[
                                                    OrderNavigationStyles.stripe,
                                                    OrderNavigationStyles.stripeRight,
                                                    index < activeNavigationEntryIndex ? OrderNavigationStyles.activeStripe : '',
                                                ].join(' ')}
                                            />
                                        )}
                                    </div>

                                    <div>
                                        <p>{index + 1}</p>
                                        <p>{navigationEntry.text}</p>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </>
    );
};

export default OrderNavigation;
