// extracted by mini-css-extract-plugin
export var articles = "services-hs-module--articles--5be1d";
export var bottomWrapper = "services-hs-module--bottom-wrapper--aa1ac";
export var buttonWrapper = "services-hs-module--button-wrapper--84cd1";
export var costEstimationWrapper = "services-hs-module--cost-estimation-wrapper--cedd2";
export var deleteIcon = "services-hs-module--delete-icon--fe50b";
export var header = "services-hs-module--header--9fba0";
export var icon = "services-hs-module--icon--2759e";
export var manufacturer = "services-hs-module--manufacturer--6ca50";
export var orderServices = "services-hs-module--order-services--58294";
export var testOverlay = "services-hs-module--test-overlay--dc310";