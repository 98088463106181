import React, { FC } from 'react';
import FocusTrap from 'react-focus-trap';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';
import { AdditionalService } from '../../../_shared/interfaces/order';
import AdditionalServicesCard from './additional-services-container';
import { useAdditionalServices } from './additional-services-context';
import Modal from './modal';
import VisibilityToggle from './visibility-toggle';

export type AdditionalServiceChangeEvent = {
    event: React.ChangeEvent<HTMLInputElement>;
    productName: string;
    parentServiceId: string;
    service: AdditionalService;
};

type Props = {
    buttonText: string;
    headline: string;
    optionsHeadline: string | undefined;
    storyblokCommon: StoryblokCommon;
    copytext: any;
};

const AdditionalServices: FC<Props> = ({ headline, storyblokCommon, copytext, buttonText, optionsHeadline = '' }) => {
    const {
        state: { visible },
    } = useAdditionalServices();
    return (
        <VisibilityToggle isVisble={visible}>
            <FocusTrap>
                <Modal>
                    <AdditionalServicesCard
                        optionsHeadline={optionsHeadline}
                        headline={headline}
                        storyblokCommon={storyblokCommon}
                        copytext={copytext}
                        nextButtonText={buttonText}
                    />
                </Modal>
            </FocusTrap>
        </VisibilityToggle>
    );
};

export default AdditionalServices;
