// extracted by mini-css-extract-plugin
export var addressError = "to-customer-dr-module--address-error--137bb";
export var addressForm = "to-customer-dr-module--address-form--1c877";
export var button = "to-customer-dr-module--button--0423d";
export var delimiter = "to-customer-dr-module--delimiter--d1969";
export var deliveryTypesList = "to-customer-dr-module--delivery-types-list--6fcdc";
export var formField = "to-customer-dr-module--form-field--e7e3c";
export var header = "to-customer-dr-module--header--c0365";
export var label = "to-customer-dr-module--label--09455";
export var labelHeadline = "to-customer-dr-module--label-headline--d6dfc";
export var price = "to-customer-dr-module--price--af2a9";