import React from 'react';
import { OrderContext } from '../../../../context/order/order.context';
import DatePicker, { registerLocale } from 'react-datepicker';
import { handleAzureAdB2cLinks } from '../../../../lib/order/order';
import AddressForm from '../../../_common/address-form/address-form';
import { defaultAddress, isRichText } from '../../../../lib/helpers/helpers';
import { AccountContext } from '../../../../context/account/account.context';
import AddressListPicker from '../../../_common/address-list-picker/address-list-picker';
import { getRichTextMarkup, StoryblokCommon } from '../../../../lib/storyblok/storyblok';
import { OrderDeliveryFromCustomerStoryblok } from '../../../../_shared/interfaces/storyblok';
import { FormattedNumber } from 'react-intl';
import { IAddress } from '../../../../_shared/interfaces/address';

import * as OrderDeliveryFromCustomerStylesHs from './from-customer.hs.module.scss';
import * as OrderDeliveryFromCustomerStylesDr from './from-customer.dr.module.scss';

import * as FormStylesHs from '../../../../_scss/modules/_forms.hs.module.scss';
import * as FormStylesDr from '../../../../_scss/modules/_forms.dr.module.scss';

import '../../../../_scss/react-datepicker.scss';

import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import ko from 'date-fns/locale/ko';
import nl from 'date-fns/locale/nl';
import { trackEvent } from '../../../../lib/track/track';

interface IPickupService {
    exists: boolean;
    price: number;
}
const isWeekday = (date: Date): boolean => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
};

const findFirstValidPickupDate = (): Date => {
    const d = new Date();
    d.setDate(d.getDate() + 2);
    while (!isWeekday(d)) {
        d.setDate(d.getDate() + 1);
    }
    return d;
};

const minDate = findFirstValidPickupDate();

export const OrderDeliveryFromCustomer = ({
    blok,
    showErrors,
    storyblokCommon,
}: {
    blok: OrderDeliveryFromCustomerStoryblok;
    showErrors: boolean;
    storyblokCommon: StoryblokCommon;
}) => {
    const OrderDeliveryFromCustomerStyles =
        storyblokCommon.configuration.content.theme === 'dr' ? OrderDeliveryFromCustomerStylesDr : OrderDeliveryFromCustomerStylesHs;
    const FormStyles = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr : FormStylesHs;
    const [isReady, setIsReady] = React.useState(false);
    const { order, updateOrder, isRestored } = React.useContext(OrderContext);
    const accountContext = React.useContext(AccountContext);
    const [initialAddress, setInitialAddress] = React.useState<IAddress>(
        order.deliveryFromCustomer?.pickupDetails?.address ?? defaultAddress,
    );
    const setDeliveryFromCustomer = (type: 'self-organized' | 'pickup') => {
        updateOrder({
            ...order,
            deliveryFromCustomer: {
                ...order.deliveryFromCustomer,
                type,
                price: type === 'pickup' ? pickupService.price : 0,
                pickupDetails: {
                    ...order.deliveryFromCustomer?.pickupDetails,
                    date:
                        type === 'pickup'
                            ? order.deliveryFromCustomer?.pickupDetails?.date
                                ? order.deliveryFromCustomer?.pickupDetails?.date
                                : minDate.toJSON()
                            : undefined,
                },
            },
        });

        trackEvent('serviceorder.fieldChanged', {
            serviceorder: { type: 'workshop_service', name: '2:Shipment' },
            serviceorderField: { name: type === 'pickup' ? 'Pickup' : 'Delivery' },
        });
    };

    const setPickupDetails = (pickupDetails: any, isDate?: boolean) => {
        if (isDate && !pickupDetails.date) {
            pickupDetails.date = minDate.toJSON();
        }

        updateOrder({
            deliveryFromCustomer: {
                ...order.deliveryFromCustomer,
                pickupDetails: { ...order.deliveryFromCustomer?.pickupDetails, ...pickupDetails },
            },
        });
    };

    const getInitialAddress = (): any => {
        const address: any = { ...defaultAddress };

        if (order.deliveryFromCustomer?.pickupDetails?.address) {
            const currentPickUpAddress: any = order.deliveryFromCustomer.pickupDetails.address;
            for (const addressProp in currentPickUpAddress) {
                if (currentPickUpAddress.hasOwnProperty(addressProp) && currentPickUpAddress[addressProp]) {
                    address[addressProp] = currentPickUpAddress[addressProp];
                }
            }
        }
        return address;
    };

    const getPickUpServiceDetails = (manufacturer: string | undefined): IPickupService => {
        if (manufacturer) {
            const manufacturerData = storyblokCommon.workshopServices.content.manufacturer.find((m) => m.name === manufacturer);
            return {
                exists: Boolean(manufacturerData?.pickup_service),
                price: manufacturerData?.pickup_service_costs ?? 0,
            };
        }
        return { exists: false, price: 0 };
    };

    const handlePageClick = (e: MouseEvent): void => {
        handleAzureAdB2cLinks(e, accountContext, storyblokCommon);
    };

    const pickupService = getPickUpServiceDetails(order.manufacturer);

    React.useEffect(() => {
        if (isRestored) {
            setInitialAddress(getInitialAddress());
        }
    }, [isRestored]);

    React.useEffect(() => {
        if (storyblokCommon.configuration.content.language === 'de') {
            registerLocale('de', de);
        } else if (storyblokCommon.configuration.content.language === 'fr') {
            registerLocale('fr', fr);
        } else if (storyblokCommon.configuration.content.language === 'ko') {
            registerLocale('ko', ko);
        } else if (storyblokCommon.configuration.content.language === 'nl') {
            registerLocale('nl', nl);
        }
        setIsReady(true);

        if (typeof window !== 'undefined') {
            document.addEventListener('click', handlePageClick);
            return () => {
                document.removeEventListener('click', handlePageClick);
            };
        }
    }, []);

    return (
        <>
            {isReady && OrderDeliveryFromCustomerStyles && FormStyles && isRestored && (
                <div>
                    <div className={OrderDeliveryFromCustomerStyles.header}>
                        <h5>{blok.headline}</h5>
                        <p>{blok.copytext}</p>
                        {isRichText(blok.copytext_guest) && !accountContext.account && (
                            <div dangerouslySetInnerHTML={getRichTextMarkup(blok.copytext_guest)} style={{ paddingTop: '24px' }} />
                        )}
                    </div>

                    <ul className={OrderDeliveryFromCustomerStyles.deliveryTypesList}>
                        <li>
                            <div className={[FormStyles.formField, FormStyles.radio, OrderDeliveryFromCustomerStyles.formField].join(' ')}>
                                <input
                                    id="order-delivery-from-customer-self-organized"
                                    type="radio"
                                    name="order-delivery-from-customer"
                                    value=""
                                    checked={order.deliveryFromCustomer?.type === 'self-organized'}
                                    onChange={() => setDeliveryFromCustomer('self-organized')}
                                />
                                <label className={FormStyles.radioLabel} htmlFor={'order-delivery-from-customer-self-organized'}>
                                    {blok.self_organized_label}
                                </label>

                                <div className={OrderDeliveryFromCustomerStyles.price} />
                            </div>
                        </li>
                        {pickupService.exists && (
                            <li>
                                <div
                                    className={[FormStyles.formField, FormStyles.radio, OrderDeliveryFromCustomerStyles.formField].join(
                                        ' ',
                                    )}
                                >
                                    <input
                                        id="order-delivery-from-customer-pickup"
                                        type="radio"
                                        name="order-delivery-from-customer"
                                        value=""
                                        checked={order.deliveryFromCustomer?.type === 'pickup'}
                                        onChange={() => setDeliveryFromCustomer('pickup')}
                                    />
                                    <label className={FormStyles.radioLabel} htmlFor={'order-delivery-from-customer-pickup'}>
                                        {blok.pickup_service_label}
                                    </label>

                                    <div className={OrderDeliveryFromCustomerStyles.price}>
                                        <p>
                                            <FormattedNumber
                                                value={pickupService.price ?? 0}
                                                style="currency"
                                                currency={storyblokCommon.configuration.content.currency}
                                            />
                                        </p>
                                    </div>
                                </div>

                                {order.deliveryFromCustomer?.type === 'pickup' && (
                                    <div className={OrderDeliveryFromCustomerStyles.pickUpForm}>
                                        <div className={FormStyles.formField}>
                                            <label
                                                className={OrderDeliveryFromCustomerStyles.label}
                                                htmlFor="order-delivery-pickup-date-input"
                                            >
                                                {blok.pickup_service_date_label}*
                                            </label>
                                            {minDate && (
                                                <DatePicker
                                                    id="order-delivery-pickup-date-input"
                                                    locale={storyblokCommon.configuration.content.language}
                                                    dateFormat="P"
                                                    filterDate={isWeekday}
                                                    minDate={minDate}
                                                    selected={
                                                        order.deliveryFromCustomer?.pickupDetails?.date
                                                            ? new Date(order.deliveryFromCustomer?.pickupDetails?.date)
                                                            : minDate
                                                    }
                                                    onChange={(e) =>
                                                        setPickupDetails({ date: !Array.isArray(e) ? e?.toJSON() : new Date() }, true)
                                                    }
                                                />
                                            )}
                                        </div>

                                        <label className={OrderDeliveryFromCustomerStyles.labelHeadline}>
                                            {blok.pickup_service_address_label}
                                            {accountContext.account && accountContext.addresses && <>*</>}
                                        </label>

                                        {accountContext.account ? (
                                            <>
                                                <AddressListPicker
                                                    storyblokCommon={storyblokCommon}
                                                    selectedId={order.deliveryFromCustomer?.pickupDetails?.addressId}
                                                    onAddressChange={(addressId) => {
                                                        setPickupDetails({
                                                            address: accountContext.addresses?.find((address) => address.id === addressId),
                                                            addressId,
                                                        });
                                                    }}
                                                />
                                                {showErrors && !order.deliveryFromCustomer?.pickupDetails?.address && (
                                                    <p
                                                        className={[FormStyles.error, OrderDeliveryFromCustomerStyles.addressError].join(
                                                            ' ',
                                                        )}
                                                    >
                                                        {blok.pickup_service_address_error}
                                                    </p>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <AddressForm
                                                    storyblokCommon={storyblokCommon}
                                                    initialAddress={initialAddress}
                                                    onChange={(address: any) => setPickupDetails({ address })}
                                                    showErrors={showErrors}
                                                />
                                                <div className={OrderDeliveryFromCustomerStyles.delimiter} />
                                            </>
                                        )}

                                        <div>
                                            <div className={FormStyles.formField}>
                                                <label htmlFor="order-delivery-from-customer-phone">
                                                    {blok.pickup_service_phone_label}*
                                                </label>
                                                <input
                                                    id="order-delivery-from-customer-phone"
                                                    name="order-delivery-from-customer-phone"
                                                    type="text"
                                                    maxLength={40}
                                                    autoComplete="tel"
                                                    value={
                                                        order.deliveryFromCustomer?.pickupDetails?.phone
                                                            ? order.deliveryFromCustomer.pickupDetails.phone
                                                            : ''
                                                    }
                                                    onChange={(e) => setPickupDetails({ phone: e.target.value })}
                                                    onBlur={(e) => setPickupDetails({ phone: e.target.value.trim() })}
                                                    className={
                                                        order.deliveryFromCustomer?.type === 'pickup' &&
                                                        !order.deliveryFromCustomer?.pickupDetails?.phone &&
                                                        showErrors
                                                            ? FormStyles.inputValidationError
                                                            : ''
                                                    }
                                                />

                                                {order.deliveryFromCustomer?.type === 'pickup' &&
                                                !order.deliveryFromCustomer?.pickupDetails?.phone &&
                                                showErrors ? (
                                                    <p className={FormStyles.error}>{blok.pickup_service_phone_error}</p>
                                                ) : null}
                                            </div>

                                            <div className={FormStyles.formField}>
                                                <label htmlFor="order-delivery-from-customer-notes">{blok.pickup_service_note_label}</label>
                                                <input
                                                    id="order-delivery-from-customer-notes"
                                                    name="order-delivery-from-customer-notes"
                                                    type="text"
                                                    maxLength={40}
                                                    value={
                                                        order.deliveryFromCustomer?.pickupDetails?.notes
                                                            ? order.deliveryFromCustomer.pickupDetails.notes
                                                            : ''
                                                    }
                                                    onChange={(e) => setPickupDetails({ notes: e.target.value })}
                                                    onBlur={(e) => setPickupDetails({ notes: e.target.value.trim() })}
                                                />
                                            </div>

                                            <p>
                                                <small>{blok.pickup_service_disclaimer}</small>
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </li>
                        )}
                    </ul>
                </div>
            )}
        </>
    );
};

export default OrderDeliveryFromCustomer;
