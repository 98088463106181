import React, { useMemo } from 'react';
import { useShoppingCartDetails, useShoppingCartStyles, useShoppingCartStoryBlok } from './shopping-cart-context';

const ShoppingCartAdditionalCosts = () => {
    const { additionalCosts: { value, formatted = '' } = {} } = useShoppingCartDetails();
    const styles = useShoppingCartStyles();
    const blok = useShoppingCartStoryBlok();
    const AdditionalCosts = useMemo(
        () => (
            <div className={styles.additionalCosts}>
                {value ? (
                    <p>{blok.additional_costs?.replace('{costs}', formatted)}</p>
                ) : (
                    <p>
                        <small>{blok.no_additional_costs}</small>
                    </p>
                )}
            </div>
        ),
        [value, formatted],
    );
    return AdditionalCosts;
};
export default ShoppingCartAdditionalCosts;
