// extracted by mini-css-extract-plugin
export var additionalCosts = "shopping-cart-dr-module--additionalCosts--620bc";
export var deleteButton = "shopping-cart-dr-module--delete-button--d3d1b";
export var editButtonContainer = "shopping-cart-dr-module--edit-button-container--5d20b";
export var grey = "shopping-cart-dr-module--grey--dfbb9";
export var header = "shopping-cart-dr-module--header--ad1c2";
export var orderShoppingCart = "shopping-cart-dr-module--order-shopping-cart--49c44";
export var price = "shopping-cart-dr-module--price--78326";
export var productName = "shopping-cart-dr-module--product-name--b601c";
export var section = "shopping-cart-dr-module--section--f70d8";
export var sectionAction = "shopping-cart-dr-module--section-action--343a7";
export var sectionName = "shopping-cart-dr-module--section-name--b8e95";
export var separator = "shopping-cart-dr-module--separator--1ca78";
export var subtotalName = "shopping-cart-dr-module--subtotal-name--2db00";
export var total = "shopping-cart-dr-module--total--4026e";
export var totalPrice = "shopping-cart-dr-module--total-price--20950";
export var vat = "shopping-cart-dr-module--vat--3005e";