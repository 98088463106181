import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { OrderShoppingCartStoryblok } from '../../../_shared/interfaces/storyblok';
import { OrderContext, StepState } from '../../../context/order/order.context';
import { getShoppingCartDetails } from '../../../lib/order/order';
import { getRichTextMarkup, StoryblokCommon } from '../../../lib/storyblok/storyblok';
import OrderShoppingCartConfirmation from './shopping-cart-confirmation';
import { ShoppingCartContextProvider } from './shopping-cart-context';
import Header from './shopping-cart-header';
import Subtotal from './shopping-cart-subtotal';
import ArticlesList from './shopping-cart-article-list';
import Pickup from './shopping-cart-pickup';
import Delivery from './shopping-cart-delivery';
import Total from './shopping-cart-total';
import AdditionalCosts from './shopping-cart-additional-costs';
import Separator from './shopping-cart-separator';
import Container from './shopping-cart-container';
import AccountContext, { IAccountContext } from '../../../context/account/account.context';

import * as ShoppingCartStylesHs from './shopping-cart.hs.module.scss';
import * as ShoppingCartStylesDr from './shopping-cart.dr.module.scss';
import { isRichText } from '../../../lib/helpers/helpers';

type Props = {
    blok: OrderShoppingCartStoryblok;
    step: string;
    storyblokCommon: StoryblokCommon;
    costEstimateText: any;
    isCostEstimate: boolean;
};

const OrderShoppingCart: FC<Props> = ({
    blok = {} as OrderShoppingCartStoryblok,
    step,
    storyblokCommon,
    costEstimateText,
    isCostEstimate,
}) => {
    const intl = useIntl();
    const accountContext = React.useContext<IAccountContext>(AccountContext);
    const orderContext = React.useContext(OrderContext);
    const ShoppingCartStyles = storyblokCommon.configuration.content.theme === 'dr' ? ShoppingCartStylesDr : ShoppingCartStylesHs;

    const cartDetails = getShoppingCartDetails(
        orderContext.order,
        storyblokCommon.workshopServices.content,
        intl,
        storyblokCommon.configuration.content.currency,
        storyblokCommon.configuration.content.vat,
    );

    return (
        <ShoppingCartContextProvider storyblokCommon={storyblokCommon} shoppingCartStoryblok={blok} cartDetails={cartDetails}>
            <Container>
                <Header isCostEstimate={isCostEstimate} />
                <ArticlesList />
                <Separator />
                <Subtotal />

                {orderContext.stepStates.delivery === StepState.Done ? (
                    <>
                        <Separator />
                        <Pickup />
                        <Delivery />
                        <Separator />
                        <Total />
                    </>
                ) : (
                    <></>
                )}

                <AdditionalCosts />
                <OrderShoppingCartConfirmation />
                {(step === 'delivery' || step === 'payment') && !accountContext.account && isRichText(costEstimateText) && (
                    <div className={ShoppingCartStyles.costEstimateInfo}>
                        <i className={ShoppingCartStyles.icon} />
                        <small dangerouslySetInnerHTML={getRichTextMarkup(costEstimateText)} style={{ paddingLeft: '24px' }} />
                    </div>
                )}
            </Container>
        </ShoppingCartContextProvider>
    );
};

export default OrderShoppingCart;
