import React from 'react';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';
import HsButton from '../hs-button/hs-button';

import * as HsFileUploadStylesHs from './hs-file-upload.hs.module.scss';
import * as HsFileUploadStylesDr from './hs-file-upload.dr.module.scss';

const HsFileUpload = ({
    children,
    storyblokCommon,
    id,
    placeholder,
    onChange,
}: {
    children?: any;
    storyblokCommon: StoryblokCommon;
    id?: string;
    placeholder?: string;
    onChange: (file: File | undefined) => void;
}) => {
    const [filename, setFilename] = React.useState<string>('');
    const HsFileUploadStyles = storyblokCommon.configuration.content.theme === 'dr' ? HsFileUploadStylesDr : HsFileUploadStylesHs;
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const onFileChange = (file: File | undefined) => {
        setFilename(file?.name ?? '');
        onChange(file);
    };

    const handleClick = () => {
        hiddenFileInput?.current?.click();
    };

    return (
        <>
            {filename !== null && (
                <div id={id} className={HsFileUploadStyles.hsFileUpload}>
                    <input
                        className={HsFileUploadStyles.inputFile}
                        type="text"
                        value={filename}
                        placeholder={placeholder}
                        onChange={(e) => setFilename(e.target.value)}
                        onBlur={(e) => setFilename(e.target.value.trim())}
                    />
                    <HsButton
                        className={HsFileUploadStyles.inputFileButton}
                        appearance="primary"
                        icon="plus"
                        onClick={handleClick}
                        storyblokCommon={storyblokCommon}
                    >
                        {children}
                    </HsButton>
                    <input
                        className={HsFileUploadStyles.inputFileHiden}
                        type="file"
                        accept="application/pdf"
                        ref={hiddenFileInput}
                        onChange={(e) => onFileChange(e.target?.files ? e.target?.files[0] : undefined)}
                    />
                </div>
            )}
        </>
    );
};

export default HsFileUpload;
