import React from 'react';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';

declare global {
    interface Window {
        hbspt: any;
    }
}

const HubspotFormInclude = ({
    region,
    portalId,
    formId,
    storyblokCommon,
}: {
    region: string;
    portalId: string;
    formId: string;
    storyblokCommon: StoryblokCommon;
}) => {
    const [lastHubspotParams, setLastHubspotParams] = React.useState({ region: '', portalId: '', formId: '' });

    if (storyblokCommon.configuration.content.theme === 'dr') {
        import('./hubspot-form.dr.scss').then();
    } else {
        import('./hubspot-form.hs.scss').then();
    }

    const createForm = (): void => {
        if (
            typeof window !== 'undefined' &&
            window.hbspt &&
            (lastHubspotParams.region !== region || lastHubspotParams.portalId !== portalId || lastHubspotParams.formId !== formId)
        ) {
            setLastHubspotParams({ region, portalId, formId });

            window.hbspt.forms.create({
                region,
                portalId,
                formId,
                target: '#hubspot-form',
            });
        }
    };

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            const script = document.createElement('script');
            script.src = 'https://js.hsforms.net/forms/v2.js';
            document.body.appendChild(script);

            script.addEventListener('load', () => {
                createForm();
            });
        }
    }, []);

    return <div id="hubspot-form" className={'theme-' + storyblokCommon.configuration.content.theme} />;
};

export default HubspotFormInclude;
