import React, { FC } from 'react';
import * as AdditionalServicesStyles from './additional-services.module.scss';

type Props = {
    title: string;
};

const AdditionalServicesCardHeader: FC<Props> = ({ title = '' }) => (
    <div className={AdditionalServicesStyles.header}>
        <h3>{title}</h3>
    </div>
);

export default AdditionalServicesCardHeader;
