import React from 'react';
import { isEmailValid, newUniqueId, isPhoneNumberValid } from '../../../lib/helpers/helpers';
import { IContact } from '../../../_shared/interfaces/contact';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';

import * as FormStylesHs from '../../../_scss/modules/_forms.hs.module.scss';
import * as FormStylesDr from '../../../_scss/modules/_forms.dr.module.scss';
import { trackEvent } from '../../../lib/track/track';

const ContactForm = ({
    initialData,
    showErrors,
    onChange,
    storyblokCommon,
    isOnSiteService,
}: {
    initialData?: IContact;
    showErrors?: boolean;
    onChange?: (values: IContact) => any;
    storyblokCommon: StoryblokCommon;
    isOnSiteService?: boolean;
}) => {
    const [uniqueId] = React.useState(newUniqueId());
    const [contactData, setContactData] = React.useState<IContact>({
        firstName: '',
        lastName: '',
        eMail: '',
        phoneNumber: '',
        taxNumber: '',
    });
    const FormStyles = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr : FormStylesHs;

    const updateContactData = (data: IContact): void => {
        setContactData(data);
        if (onChange) {
            onChange(data);
        }
    };

    const trackOnsiteServiceKeyDownActions = React.useCallback(() => {
        document.getElementById('contact')?.removeEventListener('keydown', trackOnsiteServiceKeyDownActions, false);
        trackEvent('serviceorder.open', { serviceorder: { type: 'onsite_service' } });
    }, []);

    React.useEffect(() => {
        if (initialData) {
            updateContactData(initialData);
        }
    }, [initialData]);

    React.useEffect(() => {
        if (typeof window !== 'undefined' && isOnSiteService) {
            document.getElementById('contact')?.addEventListener('keydown', trackOnsiteServiceKeyDownActions, false);
            return () => {
                document.getElementById('contact')?.removeEventListener('keydown', trackOnsiteServiceKeyDownActions, false);
            };
        }
    }, []);

    return (
        <>
            {FormStyles && (
                <form id="contact" noValidate={true}>
                    <div className={FormStyles.formGrid}>
                        <div className={FormStyles.formField}>
                            <label htmlFor={'contact-first-name-' + uniqueId}>
                                {storyblokCommon.configuration.content.contact_first_name_label}*
                            </label>
                            <input
                                id={'contact-first-name-' + uniqueId}
                                name="firstName"
                                type="text"
                                maxLength={35}
                                autoComplete="given-name"
                                onChange={(e) => updateContactData({ ...contactData, firstName: e.target.value })}
                                onBlur={(e) => updateContactData({ ...contactData, firstName: e.target.value.trim() })}
                                value={contactData.firstName}
                                className={showErrors && !contactData.firstName ? FormStyles.inputValidationError : ''}
                            />
                            {showErrors && !contactData.firstName && (
                                <p className={FormStyles.error}>{storyblokCommon.configuration.content.contact_first_name_error}</p>
                            )}
                        </div>

                        <div className={FormStyles.formField}>
                            <label htmlFor={'contact-last-name-' + uniqueId}>
                                {storyblokCommon.configuration.content.contact_last_name_label}*
                            </label>
                            <input
                                id={'contact-last-name-' + uniqueId}
                                name="lastName"
                                type="text"
                                maxLength={35}
                                autoComplete="family-name"
                                onChange={(e) => updateContactData({ ...contactData, lastName: e.target.value })}
                                onBlur={(e) => updateContactData({ ...contactData, lastName: e.target.value.trim() })}
                                value={contactData.lastName}
                                className={showErrors && !contactData.lastName ? FormStyles.inputValidationError : ''}
                            />
                            {showErrors && !contactData.lastName && (
                                <p className={FormStyles.error}>{storyblokCommon.configuration.content.contact_last_name_error}</p>
                            )}
                        </div>
                    </div>

                    <div className={FormStyles.formGrid}>
                        <div className={FormStyles.formField}>
                            <label htmlFor={'contact-e-mail-' + uniqueId}>
                                {storyblokCommon.configuration.content.contact_email_label}*
                            </label>
                            <input
                                id={'contact-e-mail-' + uniqueId}
                                name="e-mail"
                                type="email"
                                maxLength={119}
                                autoComplete="email"
                                onChange={(e) => updateContactData({ ...contactData, eMail: e.target.value })}
                                onBlur={(e) => updateContactData({ ...contactData, eMail: e.target.value.trim() })}
                                value={contactData.eMail}
                                className={
                                    showErrors && (!contactData.eMail || !isEmailValid(contactData.eMail))
                                        ? FormStyles.inputValidationError
                                        : ''
                                }
                            />
                            {showErrors && !contactData.eMail && (
                                <p className={FormStyles.error}>{storyblokCommon.configuration.content.contact_email_error_required}</p>
                            )}
                            {showErrors && contactData.eMail && !isEmailValid(contactData.eMail) && (
                                <p className={FormStyles.error}>{storyblokCommon.configuration.content.contact_email_error_format}</p>
                            )}
                        </div>

                        <div className={FormStyles.formField}>
                            <label htmlFor={'contact-phone-number-' + uniqueId}>
                                {storyblokCommon.configuration.content.contact_phone_label}*
                            </label>
                            <input
                                id={'contact-phone-number-' + uniqueId}
                                name="phone-number"
                                type="tel"
                                maxLength={100}
                                autoComplete="tel"
                                onChange={(e) => updateContactData({ ...contactData, phoneNumber: e.target.value })}
                                onBlur={(e) => updateContactData({ ...contactData, phoneNumber: e.target.value.trim() })}
                                value={contactData.phoneNumber}
                                className={
                                    showErrors && (!contactData.phoneNumber || !isPhoneNumberValid(contactData.phoneNumber))
                                        ? FormStyles.inputValidationError
                                        : ''
                                }
                            />
                            {showErrors && !contactData.phoneNumber && (
                                <p className={FormStyles.error}>{storyblokCommon.configuration.content.contact_phone_error}</p>
                            )}
                            {showErrors && contactData.phoneNumber && !isPhoneNumberValid(contactData.phoneNumber) && (
                                <p className={FormStyles.error}>{storyblokCommon.configuration.content.contact_phone_error_format}</p>
                            )}
                        </div>
                    </div>

                    {storyblokCommon.configuration.content.contact_tax_number_label && (
                        <div className={FormStyles.formGrid}>
                            <div className={FormStyles.formField}>
                                <label htmlFor={'contact-tax-number-' + uniqueId}>
                                    {storyblokCommon.configuration.content.contact_tax_number_label}*
                                </label>
                                <input
                                    id={'contact-tax-number-' + uniqueId}
                                    name="tax-number"
                                    type="text"
                                    maxLength={20}
                                    onChange={(e) => updateContactData({ ...contactData, taxNumber: e.target.value })}
                                    onBlur={(e) => updateContactData({ ...contactData, taxNumber: e.target.value.trim() })}
                                    value={contactData.taxNumber}
                                    className={showErrors && !contactData.taxNumber ? FormStyles.inputValidationError : ''}
                                />
                                {showErrors && !contactData.taxNumber && (
                                    <p className={FormStyles.error}>{storyblokCommon.configuration.content.contact_tax_number_error}</p>
                                )}
                            </div>
                        </div>
                    )}
                </form>
            )}
        </>
    );
};

export default ContactForm;
