import React, { FC, useState } from 'react';

type Props = {
    children: React.ReactElement<any> | React.ReactElement<any>[];
    isVisble: boolean;
};

const VisibilityToggle: FC<Props> = ({ children, isVisble }) => {
    return <>{isVisble ? children : <></>}</>;
};

export default VisibilityToggle;
