import _ from 'lodash';
import React from 'react';
import { IOrder } from '../../../_shared/interfaces/order';
import HsButton from '../../_common/hs-button/hs-button';
import { OrderContext, StepState } from '../../../context/order/order.context';
import LoadingAnimationContext from '../../../context/loading-animation/loading-animation.context';
import { isRichText, replaceInRichText, scrollToFirstError } from '../../../lib/helpers/helpers';
import { getOrderRequestData, submitOrder, submitCostEstimate, handleAzureAdB2cLinks } from '../../../lib/order/order';
import { AccountContext, IAccountContext } from '../../../context/account/account.context';
import { getRichTextMarkup, StoryblokCommon } from '../../../lib/storyblok/storyblok';
import { navigate } from 'gatsby';
import { OrderConfirmationStoryblok } from '../../../_shared/interfaces/storyblok';

import * as OrderConfirmationStylesHs from './confirmation.hs.module.scss';
import * as OrderConfirmationStylesDr from './confirmation.dr.module.scss';

import * as FormStylesHs from '../../../_scss/modules/_forms.hs.module.scss';
import * as FormStylesDr from '../../../_scss/modules/_forms.dr.module.scss';
import { trackEvent } from '../../../lib/track/track';
import { ICostEstimate } from '../../../_shared/interfaces/cost-estimate';

const OrderConfirmation = ({
    blok,
    step,
    storyblokCommon,
    setExternalShowErrors,
    hasExternalErrors,
    costEstimateEnabled,
    costEstimateText,
    costEstimate,
}: {
    blok: OrderConfirmationStoryblok;
    step: string;
    storyblokCommon: StoryblokCommon;
    setExternalShowErrors: (b: boolean) => void;
    hasExternalErrors: boolean;
    costEstimateEnabled: boolean;
    costEstimateText: any;
    costEstimate?: ICostEstimate;
}) => {
    const { order, stepStates, resetOrder } = React.useContext(OrderContext);
    const accountContext = React.useContext<IAccountContext>(AccountContext);
    const [checkedIsBusinessCustomer, setCheckIsBusinessCustomer] = React.useState(false);
    const [checkedTermsAndConditions, setCheckTermsAndConditions] = React.useState(false);
    const [checkedAllStepsReady, setCheckAllStepsReady] = React.useState(true); // TODO: wieder inital auf false setzen
    const [submitDisabled, setSubmitDisabled] = React.useState(false);
    const [confirmOrderVisible, setConfirmOrderVisible] = React.useState(false);
    const { showLoadingAnimation, hideLoadingAnimation } = React.useContext(LoadingAnimationContext);
    const OrderConfirmationStyles =
        storyblokCommon.configuration.content.theme === 'dr' ? OrderConfirmationStylesDr : OrderConfirmationStylesHs;
    const FormStyles = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr : FormStylesHs;
    const formStylesError = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr.error : FormStylesHs.error;
    const [showErrors, setShowErrors] = React.useState(false);
    const [retailerName, setRetailerName] = React.useState('');

    const initTermsAndConditionsConfirmation = () => {
        const retailerId = localStorage.getItem('retailer') ?? undefined;

        if (retailerId) {
            const retailer = storyblokCommon.configuration.content.retailers?.find((r) => r.id === retailerId);
            if (retailer) {
                setRetailerName(retailer.name);
            }
        }
    };

    const doSubmitOrder = () => {
        // check for disclaimers
        if (submitDisabled) {
            setShowErrors(true);
            setExternalShowErrors(true);
            return false;
        }

        // check for anything else
        getOrderRequestData(order, storyblokCommon, process.env.GATSBY_COUNTRY, !!accountContext.account)
            .then((orderRequestData) => {
                const email = order.customer?.eMail;
                showLoadingAnimation();

                accountContext
                    .updateToken()
                    .then(() => {
                        submitOrder(process.env.GATSBY_COUNTRY, orderRequestData, order.purchaseOrderFile)
                            .then(() => {
                                trackEvent('serviceorder.sent', { serviceorder: { type: 'workshop_service', name: '4:Check&Order' } });
                                if (accountContext.account) {
                                    // update devices
                                    accountContext.devicesRequest(true).then(() => {
                                        resetOrder().then(() => {
                                            hideLoadingAnimation();
                                            navigate(self.location.pathname, { state: { step: 'success', email } });
                                        });
                                    });
                                } else {
                                    const orderCopy = _.cloneDeep(order);
                                    resetOrder().then(() => {
                                        hideLoadingAnimation();
                                        navigate(self.location.pathname, { state: { step: 'success', email, currentOrder: orderCopy } });
                                    });
                                }
                            })
                            .catch(() => {
                                // backend error
                                hideLoadingAnimation();
                                navigate(self.location.pathname, { state: { step: 'error' } });
                            });
                    })
                    .catch(() => {
                        // token refresh error
                        hideLoadingAnimation();
                        navigate(self.location.pathname, { state: { step: 'error' } });
                    });
            })
            .catch((e) => {
                // tslint:disable-next-line:no-console
                console.error(e);
                // frontend error (data missing)
                setExternalShowErrors(true);

                setTimeout(() => {
                    scrollToFirstError(formStylesError);
                });
            });
    };

    const doSubmitCostEstimate = () => {
        // check for disclaimers
        if (submitDisabled || !accountContext.account) {
            setShowErrors(true);
            setExternalShowErrors(true);
            return false;
        }

        // check for anything else
        getOrderRequestData(order, storyblokCommon, process.env.GATSBY_COUNTRY, !!accountContext.account)
            .then((orderRequestData) => {
                const email = order.customer?.eMail;
                showLoadingAnimation();

                accountContext
                    .updateToken()
                    .then(() => {
                        submitCostEstimate(process.env.GATSBY_COUNTRY, orderRequestData)
                            .then((data) => {
                                if (accountContext.account) {
                                    // update devices
                                    accountContext.devicesRequest(true).then(() => {
                                        resetOrder().then(() => {
                                            hideLoadingAnimation();
                                            accountContext.resetCostEstimates();
                                            navigate(self.location.pathname, {
                                                state: { step: 'success', email, referenceNumber: data.customerRequestNumber },
                                            });
                                        });
                                    });
                                }
                            })
                            .catch(() => {
                                // backend error
                                hideLoadingAnimation();
                                navigate(self.location.pathname, { state: { step: 'error' } });
                            });
                    })
                    .catch(() => {
                        // token refresh error
                        hideLoadingAnimation();
                        navigate(self.location.pathname, { state: { step: 'error' } });
                    });
            })
            .catch((e) => {
                // tslint:disable-next-line:no-console
                console.error(e);
                // frontend error (data missing)
                setExternalShowErrors(true);

                setTimeout(() => {
                    scrollToFirstError(formStylesError);
                });
            });
    };

    const handlePageClick = (e: MouseEvent): void => {
        handleAzureAdB2cLinks(e, accountContext, storyblokCommon);
    };

    React.useEffect(() => {
        setConfirmOrderVisible(step === 'overview' || step === 'cost-estimate');
    }, [step]);

    React.useEffect(() => {
        setSubmitDisabled(!checkedIsBusinessCustomer || !checkedTermsAndConditions || !checkedAllStepsReady);
    }, [checkedIsBusinessCustomer, checkedTermsAndConditions, checkedAllStepsReady]);

    React.useEffect(() => {
        initTermsAndConditionsConfirmation();

        if (typeof window !== 'undefined') {
            document.addEventListener('click', handlePageClick);
            return () => {
                document.removeEventListener('click', handlePageClick);
            };
        }
    }, []);

    return confirmOrderVisible && FormStyles && OrderConfirmationStyles ? (
        <div className={OrderConfirmationStyles.orderConfirmation}>
            <div className={OrderConfirmationStyles.box}>
                <input
                    id="order-confirm-b2b"
                    type="checkbox"
                    onChange={() => setCheckIsBusinessCustomer(!checkedIsBusinessCustomer)}
                    checked={checkedIsBusinessCustomer}
                    className={showErrors && !checkedIsBusinessCustomer ? FormStyles.inputValidationError : ''}
                />
                <label
                    htmlFor="order-confirm-b2b"
                    className={[FormStyles.checkboxLabel, showErrors && !checkedIsBusinessCustomer ? FormStyles.checkboxError : null].join(
                        ' ',
                    )}
                >
                    <small dangerouslySetInnerHTML={getRichTextMarkup(blok.b2c_confirmation)} />
                </label>
                {showErrors && !checkedIsBusinessCustomer ? (
                    <p className={[FormStyles.error, OrderConfirmationStyles.error].join(' ')}>
                        <small>{blok.b2c_confirmation_error}</small>
                    </p>
                ) : null}
            </div>

            <div className={OrderConfirmationStyles.box}>
                <input
                    id="order-confirm-terms-conditons"
                    type="checkbox"
                    onChange={() => setCheckTermsAndConditions(!checkedTermsAndConditions)}
                    checked={checkedTermsAndConditions}
                    className={showErrors && !checkedTermsAndConditions ? FormStyles.inputValidationError : ''}
                />
                <label
                    htmlFor="order-confirm-terms-conditons"
                    className={[FormStyles.checkboxLabel, showErrors && !checkedTermsAndConditions ? FormStyles.checkboxError : null].join(
                        ' ',
                    )}
                >
                    <small
                        dangerouslySetInnerHTML={
                            retailerName
                                ? getRichTextMarkup(replaceInRichText(blok.privacy_confirmation_retailer, '{retailerName}', retailerName))
                                : getRichTextMarkup(blok.privacy_confirmation)
                        }
                    />
                </label>
                {showErrors && !checkedTermsAndConditions ? (
                    <p className={[FormStyles.error, OrderConfirmationStyles.error].join(' ')}>
                        <small>
                            {retailerName ? <>{blok.privacy_confirmation_retailer_error}</> : <>{blok.privacy_confirmation_error}</>}
                        </small>
                    </p>
                ) : null}
            </div>

            <HsButton
                type="button"
                appearance="primary"
                onClick={doSubmitOrder}
                className={OrderConfirmationStyles.submitButton}
                storyblokCommon={storyblokCommon}
            >
                {blok.submit_button}
            </HsButton>
            {costEstimateEnabled && blok.cost_estimation_submit_button && isRichText(costEstimateText) && (
                <div className={OrderConfirmationStyles.costEstimateBox}>
                    {!order.costEstimationParentSysId && (
                        <HsButton
                            type="button"
                            appearance="secondary"
                            onClick={doSubmitCostEstimate}
                            className={OrderConfirmationStyles.submitButton}
                            storyblokCommon={storyblokCommon}
                            disabled={!accountContext.account}
                        >
                            {blok.cost_estimation_submit_button}
                        </HsButton>
                    )}
                    {!accountContext.account && (
                        <div className={OrderConfirmationStyles.costEstimateInfo}>
                            <i className={OrderConfirmationStyles.icon} />
                            <small dangerouslySetInnerHTML={getRichTextMarkup(costEstimateText)} style={{ paddingLeft: '24px' }} />
                        </div>
                    )}
                </div>
            )}

            {step === 'cost-estimate' && (
                <HsButton
                    appearance="secondary"
                    storyblokCommon={storyblokCommon}
                    className={OrderConfirmationStyles.cancelButton}
                    onClick={() => navigate(-1)}
                >
                    {storyblokCommon.configuration.content.cost_estimate_button_cancel}
                </HsButton>
            )}
        </div>
    ) : (
        <></>
    );
};

export default OrderConfirmation;
