import React, { FC } from 'react';
import { useShoppingCartDetails, useShoppingCartStyles, useShoppingCartStoryBlok } from './shopping-cart-context';
import { ShoppingCartDetails, IShoppingCartDetailsPickupService } from '../../../_shared/interfaces/order';

const ShoppingCartPickup: FC = () => {
    const { pickupService: { value = 0, formatted } = {} as IShoppingCartDetailsPickupService } =
        useShoppingCartDetails() ?? ({} as ShoppingCartDetails);
    const styles = useShoppingCartStyles();
    const blok = useShoppingCartStoryBlok();

    return value > 0 ? (
        <div className={styles.section}>
            <p className={styles.sectionName}>{blok.delivery_to_workshop}</p>
            <p className={styles.price}>{formatted}</p>
        </div>
    ) : (
        <></>
    );
};

export default ShoppingCartPickup;
