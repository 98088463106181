import React from 'react';
import OrderServices from '../services/services';
import OrderPayment from '../payment/payment';
import OrderDelivery from '../delivery/delivery';
import OrderOverview from '../overview/overview';
import OrderShoppingCart from '../shopping-cart/shopping-cart';
import OrderConfirmation from '../confirmation/confirmation';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';
import { PageOrderStoryblok } from '../../../_shared/interfaces/storyblok';
import SbEditable from 'storyblok-react';
import * as OrderLayoutStylesHs from './layout.hs.module.scss';
import * as OrderLayoutStylesDr from './layout.dr.module.scss';
import AdditionalServices from '../additional-services/additional-services';
import { OrderStep } from '../../../templates/page-order';
import OrderCostEstimate from '../cost-estimate/cost-estimate';
import { ICostEstimate } from '../../../_shared/interfaces/cost-estimate';
import { FormattedNumber } from 'react-intl';

const OrderLayout = ({
    step,
    storyblokCommon,
    pageContext,
    costEstimate,
}: {
    step: OrderStep;
    storyblokCommon: StoryblokCommon;
    pageContext: PageOrderStoryblok;
    costEstimate?: ICostEstimate;
}) => {
    const [showErrors, setShowErrors] = React.useState(false);
    const [hasErrors, setHasErrors] = React.useState(false);
    const OrderLayoutStyles = storyblokCommon.configuration.content.theme === 'dr' ? OrderLayoutStylesDr : OrderLayoutStylesHs;

    const getMainComponent = (orderStep: OrderStep) => {
        switch (orderStep) {
            case 'services':
                return (
                    <SbEditable content={pageContext.order_services[0]}>
                        <OrderServices
                            blok={pageContext.order_services[0]}
                            blokAdditionalCosts={pageContext.order_services_additional_costs[0]}
                            blokArticle={pageContext.order_services_article[0]}
                            storyblokCommon={storyblokCommon}
                            costEstimateEnabled={!!pageContext.order_cost_estimate_enabled}
                        />
                    </SbEditable>
                );
            case 'delivery':
                return (
                    <OrderDelivery
                        blokFrom={pageContext.order_delivery_from_customer[0]}
                        blokTo={pageContext.order_delivery_to_customer[0]}
                        storyblokCommon={storyblokCommon}
                    />
                );
            case 'payment':
                return (
                    <SbEditable content={pageContext.order_payment[0]}>
                        <OrderPayment blok={pageContext.order_payment[0]} storyblokCommon={storyblokCommon} />
                    </SbEditable>
                );
            case 'overview':
                return (
                    <SbEditable content={pageContext.order_overview[0]}>
                        <OrderOverview
                            blok={pageContext.order_overview[0]}
                            blokFrom={pageContext.order_delivery_from_customer[0]}
                            blokTo={pageContext.order_delivery_to_customer[0]}
                            blokPayment={pageContext.order_payment[0]}
                            showErrors={showErrors}
                            setExternalHasErrors={(b) => setHasErrors(b)}
                            storyblokCommon={storyblokCommon}
                        />
                    </SbEditable>
                );

            case 'cost-estimate':
                return (
                    <SbEditable content={pageContext.order_payment[0]}>
                        <OrderCostEstimate
                            storyblokCommon={storyblokCommon}
                            costEstimate={costEstimate as ICostEstimate}
                            blokOverview={pageContext.order_overview[0]}
                            blokFrom={pageContext.order_delivery_from_customer[0]}
                            blokTo={pageContext.order_delivery_to_customer[0]}
                            blokPayment={pageContext.order_payment[0]}
                            showErrors={showErrors}
                        />
                    </SbEditable>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className={OrderLayoutStyles.orderLayout}>
                <div>{getMainComponent(step)}</div>
                <div>
                    <div className={OrderLayoutStyles.shoppingCartBack}>
                        <SbEditable content={pageContext.order_shopping_cart[0]}>
                            <OrderShoppingCart
                                blok={pageContext.order_shopping_cart[0]}
                                step={step}
                                storyblokCommon={storyblokCommon}
                                costEstimateText={pageContext.order_cost_estimate_guest_infotext}
                                isCostEstimate={step === 'cost-estimate'}
                            />
                        </SbEditable>

                        {step === 'cost-estimate' &&
                            costEstimate &&
                            (costEstimate.missingProducts.length > 0 || costEstimate.pricesChanges) && (
                                <div className={OrderLayoutStyles.costEstimateNotes}>
                                    {costEstimate.pricesChanges && (
                                        <div className={OrderLayoutStyles.costEstimateNote}>
                                            <div>
                                                <div />
                                            </div>
                                            <p>{storyblokCommon.configuration.content.cost_estimate_note_prices}</p>
                                        </div>
                                    )}

                                    {costEstimate.missingProducts.length > 0 && (
                                        <>
                                            <div className={OrderLayoutStyles.costEstimateNote}>
                                                <div>
                                                    <div />
                                                </div>
                                                <p>{storyblokCommon.configuration.content.cost_estimate_note_products}</p>
                                            </div>

                                            <ul>
                                                {costEstimate.missingProducts.map((missingProduct, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div>
                                                                <p>
                                                                    <strong>{missingProduct.product}</strong>
                                                                </p>
                                                                {!missingProduct.additionalService && <p>{missingProduct.service}</p>}
                                                                {missingProduct.additionalService && (
                                                                    <p>Zusatzservice {missingProduct.additionalService}</p>
                                                                )}
                                                            </div>
                                                            <div>
                                                                <p>
                                                                    <FormattedNumber
                                                                        value={missingProduct.price}
                                                                        style="currency"
                                                                        currency={storyblokCommon.configuration.content.currency}
                                                                    />
                                                                </p>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </>
                                    )}
                                </div>
                            )}

                        <SbEditable content={pageContext.order_confirmation[0]}>
                            <div>
                                <OrderConfirmation
                                    blok={pageContext.order_confirmation[0]}
                                    step={step}
                                    storyblokCommon={storyblokCommon}
                                    setExternalShowErrors={(b) => setShowErrors(b)}
                                    hasExternalErrors={hasErrors}
                                    costEstimateEnabled={!!pageContext.order_cost_estimate_enabled}
                                    costEstimateText={pageContext.order_cost_estimate_guest_infotext}
                                    costEstimate={costEstimate}
                                />
                            </div>
                        </SbEditable>
                    </div>
                </div>
            </div>
            <AdditionalServices
                headline={pageContext.order_services[0].as_headline}
                copytext={pageContext.order_services[0].as_copytext}
                buttonText={pageContext.order_services[0].as_button}
                optionsHeadline={pageContext.order_services[0].as_options_headline}
                storyblokCommon={storyblokCommon}
            />
        </>
    );
};

export default OrderLayout;
