import React, { FC, memo } from 'react';
import { useShoppingCartStoryBlok, useShoppingCartStyles } from './shopping-cart-context';

const NoArticle: FC = () => {
    const blok = useShoppingCartStoryBlok();
    const styles = useShoppingCartStyles();
    return (
        <>
            <p>{blok.no_articles}</p>
            <div className={styles.seperatorLight} />
        </>
    );
};
export default memo(NoArticle);
