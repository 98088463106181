// extracted by mini-css-extract-plugin
export var addressBox = "payment-hs-module--address-box--b7018";
export var addressListPicker = "payment-hs-module--address-list-picker--96e0f";
export var button = "payment-hs-module--button--d03b4";
export var buttonWrapper = "payment-hs-module--button-wrapper--5f653";
export var formFields = "payment-hs-module--form-fields--18021";
export var icon = "payment-hs-module--icon--3273c";
export var orderPayment = "payment-hs-module--order-payment--5b913";
export var paymentMethodBillIcon = "payment-hs-module--payment-method-bill-icon--a5251";
export var paymentMethodBox = "payment-hs-module--payment-method-box--a3729";
export var paymentMethodListPicker = "payment-hs-module--payment-method-list-picker--da7a6";
export var paymentRadioBox = "payment-hs-module--payment-radio-box--59b76";
export var underline = "payment-hs-module--underline--e04f3";