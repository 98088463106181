// extracted by mini-css-extract-plugin
export var articles = "services-dr-module--articles--ed4e7";
export var bottomWrapper = "services-dr-module--bottom-wrapper--52c3b";
export var buttonWrapper = "services-dr-module--button-wrapper--a7a2c";
export var costEstimationWrapper = "services-dr-module--cost-estimation-wrapper--cd152";
export var deleteIcon = "services-dr-module--delete-icon--1af75";
export var header = "services-dr-module--header--06692";
export var icon = "services-dr-module--icon--da4c5";
export var manufacturer = "services-dr-module--manufacturer--dea17";
export var orderServices = "services-dr-module--order-services--1d2ac";
export var testOverlay = "services-dr-module--test-overlay--eb9da";