import React, { FC, memo } from 'react';
import { AdditionalService } from '../../../_shared/interfaces/order';
import * as styles from './additional-services.module.scss';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';
import AdditionalServiceOption from './additional-service-option';

type Props = {
    services: AdditionalService[];
    productName: string;
    storyblokCommon: StoryblokCommon;
};

const AdditionalServicesList: FC<Props> = ({ services = [], productName = '', storyblokCommon }) => {
    return (
        <ul className={styles.additionalServicesList} key={productName}>
            {services.map((service) => (
                <AdditionalServiceOption key={service.id} service={service} productName={productName} storyblokCommon={storyblokCommon} />
            ))}
        </ul>
    );
};
export default memo(AdditionalServicesList, (prev, next) => prev.productName === next.productName);
