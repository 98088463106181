import React, { FC, useContext, useMemo } from 'react';
import { IProductOptions } from '../services';
import { IArticle } from '../../../../_shared/interfaces/article';
import { getOptionByLabel } from '../../../../lib/article/article';
import { getRichTextMarkup, StoryblokCommon } from '../../../../lib/storyblok/storyblok';
import { OrderServicesArticleStoryblok, ProductStoryblok } from '../../../../_shared/interfaces/storyblok';
import * as FormStylesHs from '../../../../_scss/modules/_forms.hs.module.scss';
import * as FormStylesDr from '../../../../_scss/modules/_forms.dr.module.scss';
import * as ArticleStylesHs from './services-article.hs.module.scss';
import * as ArticleStylesDr from './services-article.dr.module.scss';
import { OrderContext } from '../../../../context/order/order.context';
import { trackEvent } from '../../../../lib/track/track';

type Props = {
    blok: OrderServicesArticleStoryblok;
    storyblokCommon: StoryblokCommon;
    productOptions: IProductOptions;
    articleIndex: number;
    showErrors?: boolean;
    manufacturer?: string;
    initialValues: IArticle;
    deviceOnly?: boolean;
    children?: any;
};

const Article: FC<Props> = ({
    blok,
    storyblokCommon,
    productOptions,
    articleIndex,
    showErrors,
    manufacturer,
    initialValues,
    deviceOnly,
    children,
}) => {
    const ArticleStyles = storyblokCommon.configuration.content.theme === 'dr' ? ArticleStylesDr : ArticleStylesHs;
    const FormStyles = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr : FormStylesHs;
    const {
        updateOrder,
        order: { articles = [] },
    } = useContext(OrderContext);
    const [trackSerialNumber, setTrackSerialNumber] = React.useState(true);
    const [trackSoftwareVersion, setTrackSoftwareVersion] = React.useState(true);
    const isServiceConsultantAvailable = (): boolean => {
        const { products = [] } = storyblokCommon.workshopServices.content.manufacturer.find((m) => m.name === manufacturer) ?? {};
        const originalProduct: ProductStoryblok = products.find((p) => p.name === currentArticle.product) ?? ({} as ProductStoryblok);
        return !!originalProduct.service_consultant_available;
    };

    const updateArticle = (
        value: string,
        attributeName: 'product' | 'service' | 'notes' | 'serialNumber' | 'softwareVersion',
        current: IArticle,
        index: number,
    ): void => {
        const article = { ...current, [attributeName]: value };
        if ('product' === attributeName) {
            article.service = '';
            article.softwareVersion = '';
        }
        updateOrder({ articles: Object.assign([...articles], { [articleIndex]: article }) });
        trackInteraction(value, attributeName, index);
    };

    const trackInteraction = (
        value: string,
        attributeName: 'product' | 'service' | 'notes' | 'serialNumber' | 'softwareVersion',
        index: number,
    ): void => {
        let trackData: any;

        switch (attributeName) {
            case 'product':
                trackData = value ? { name: `Product|count:${index + 1}`, value: { product: value } } : null;
                break;
            case 'service':
                trackData = value
                    ? {
                          name: 'Service & Accessory',
                          value: {
                              service: servicesOptions.find((x) => x.value === value)?.label,
                          },
                      }
                    : null;
                break;
            case 'serialNumber':
                if (trackSerialNumber) {
                    setTrackSerialNumber(false);
                    trackData = { name: 'Serial Number' };
                }
                break;
            case 'softwareVersion':
                if (trackSoftwareVersion) {
                    setTrackSoftwareVersion(false);
                    trackData = { name: 'Software Version' };
                }
                break;
        }
        if (trackData) {
            trackEvent('serviceorder.fieldChanged', {
                serviceorder: { type: 'workshop_service', name: '1:ServiceSelection' },
                serviceorderField: trackData,
            });
        }
    };

    const getUpdatedArticle = (article: IArticle): IArticle => {
        if (article.product && manufacturer) {
            const originalData = getOptionByLabel(productOptions[manufacturer], article.product);
            return {
                value: article.product,
                serialNumber: originalData?.isMyDevice && originalData.serialNumber ? originalData.serialNumber : article.serialNumber,
                softwareVersion:
                    originalData?.isMyDevice && originalData.softwareVersion ? originalData.softwareVersion : article.softwareVersion,
                serialNumberRepresentation:
                    originalData?.isMyDevice && originalData?.serialNumber ? 'hidden' : originalData?.serialNumber ?? 'hidden',
                softwareVersionRepresentation:
                    originalData?.isMyDevice && originalData?.softwareVersion ? 'hidden' : originalData?.softwareVersion ?? 'hidden',
                deviceId: originalData?.isMyDevice ? originalData.deviceId : undefined,
                product: originalData?.value ?? '',
                service: article.service,
            } as IArticle;
        }
        return article;
    };

    const getServicesOptions = (currentProduct: string) => {
        const $default = [{ label: storyblokCommon.configuration.content.select_default, value: '' }];
        if (!Boolean(manufacturer)) return $default;
        for (const { options = [] } of productOptions[manufacturer as string]) {
            for (const { label, services = [] } of options) {
                if (label === currentProduct && services.length > 0) return services;
            }
        }
        return $default;
    };

    const currentArticle = getUpdatedArticle(initialValues);
    const servicesOptions = useMemo(() => getServicesOptions(currentArticle.product), [currentArticle.product]);
    const { product = '' } = articles[articleIndex] ?? {};

    return (
        <>
            {ArticleStyles && FormStyles && (
                <div className={ArticleStyles.orderServicesArticle}>
                    <form>
                        <div className={FormStyles.formField}>
                            <label htmlFor={'order-services-product-' + articleIndex}>{blok.product_label}*</label>

                            <select
                                id={'order-services-product-' + articleIndex}
                                name={'order-services-product-' + articleIndex}
                                disabled={!manufacturer}
                                onChange={(e) => updateArticle(e.target.value, 'product', currentArticle, articleIndex)}
                                value={product}
                                className={showErrors && manufacturer && !currentArticle.product ? FormStyles.inputValidationError : ''}
                            >
                                <option value="">{storyblokCommon.configuration.content.select_default}</option>
                                {manufacturer &&
                                    productOptions[manufacturer].map((group: any, groupIndex: number) => {
                                        return (
                                            <optgroup key={groupIndex} label={group.label}>
                                                {group.options.map((option: { value: string; label: string }, optionIndex: number) => {
                                                    return <option key={optionIndex}>{option.label}</option>;
                                                })}
                                            </optgroup>
                                        );
                                    })}
                            </select>
                            {showErrors && manufacturer && !currentArticle.product && (
                                <p className={FormStyles.error}>{blok.product_error}</p>
                            )}
                        </div>

                        {currentArticle.product && currentArticle.serialNumberRepresentation !== 'hidden' && (
                            <div className={FormStyles.formField}>
                                <label htmlFor={'order-services-serial-number-' + articleIndex}>
                                    {blok.sn_label}
                                    {currentArticle.serialNumberRepresentation === 'mandatory' && <>*</>}
                                </label>
                                <input
                                    id={'order-services-serial-number-' + articleIndex}
                                    name={'order-services-serial-number-' + articleIndex}
                                    maxLength={40}
                                    value={currentArticle.serialNumber ?? ''}
                                    placeholder={blok.sn_placeholder}
                                    onChange={(e) => updateArticle(e.target.value, 'serialNumber', currentArticle, articleIndex)}
                                    onBlur={(e) => updateArticle(e.target.value.trim(), 'serialNumber', currentArticle, articleIndex)}
                                    className={
                                        showErrors &&
                                        manufacturer &&
                                        currentArticle.product &&
                                        currentArticle.serialNumberRepresentation === 'mandatory' &&
                                        !currentArticle.serialNumber
                                            ? FormStyles.inputValidationError
                                            : ''
                                    }
                                />
                                {showErrors &&
                                    manufacturer &&
                                    currentArticle.product &&
                                    currentArticle.serialNumberRepresentation === 'mandatory' &&
                                    !currentArticle.serialNumber && <p className={FormStyles.error}>{blok.sn_error}</p>}
                            </div>
                        )}

                        {currentArticle.product && currentArticle.softwareVersionRepresentation !== 'hidden' && (
                            <div className={FormStyles.formField}>
                                <label htmlFor={'order-services-software-version-' + articleIndex}>
                                    {blok.sv_label}
                                    {currentArticle.softwareVersionRepresentation === 'mandatory' && <>*</>}
                                </label>
                                <input
                                    id={'order-services-software-version-' + articleIndex}
                                    name={'order-services-software-version-' + articleIndex}
                                    maxLength={40}
                                    value={currentArticle.softwareVersion ?? ''}
                                    placeholder={blok.sv_placeholder}
                                    onChange={(e) => updateArticle(e.target.value, 'softwareVersion', currentArticle, articleIndex)}
                                    onBlur={(e) => updateArticle(e.target.value.trim(), 'softwareVersion', currentArticle, articleIndex)}
                                    className={
                                        showErrors &&
                                        manufacturer &&
                                        currentArticle.product &&
                                        currentArticle.softwareVersionRepresentation === 'mandatory' &&
                                        !currentArticle.softwareVersion
                                            ? FormStyles.inputValidationError
                                            : ''
                                    }
                                />
                                {showErrors &&
                                    manufacturer &&
                                    currentArticle.product &&
                                    currentArticle.softwareVersionRepresentation === 'mandatory' &&
                                    !currentArticle.softwareVersion && <p className={FormStyles.error}>{blok.sv_error}</p>}
                            </div>
                        )}

                        {!deviceOnly && (
                            <>
                                <div className={FormStyles.formField}>
                                    <label htmlFor={'order-services-service-' + articleIndex}>{blok.service_label}*</label>

                                    {blok.service_info && isServiceConsultantAvailable() && (
                                        <div
                                            style={{ paddingBottom: '3px' }}
                                            className={FormStyles.infoText}
                                            dangerouslySetInnerHTML={getRichTextMarkup(blok.service_info)}
                                        />
                                    )}

                                    <select
                                        id={'order-services-service-' + articleIndex}
                                        name={'order-services-service-' + articleIndex}
                                        disabled={!(manufacturer && currentArticle.product)}
                                        onChange={(e) => updateArticle(e.target.value, 'service', currentArticle, articleIndex)}
                                        value={currentArticle.service}
                                        className={
                                            showErrors && manufacturer && currentArticle.product && !currentArticle.service
                                                ? FormStyles.inputValidationError
                                                : ''
                                        }
                                    >
                                        <option value="">{storyblokCommon.configuration.content.select_default}</option>
                                        {servicesOptions.map((option: { label: string; value: string }, index) => {
                                            return (
                                                <option key={index} value={option.value}>
                                                    {option.label}
                                                </option>
                                            );
                                        })}
                                    </select>

                                    {showErrors && manufacturer && currentArticle.product && !currentArticle.service && (
                                        <p className={FormStyles.error}>{blok.service_error}</p>
                                    )}
                                </div>

                                <div className={[FormStyles.formField, FormStyles.fullWidth].join(' ')}>
                                    <label htmlFor={'order-services-notes-' + articleIndex}>{blok.notes_label}</label>
                                    <textarea
                                        id={'order-services-notes-' + articleIndex}
                                        name={'order-services-notes-' + articleIndex}
                                        value={currentArticle.notes}
                                        maxLength={600}
                                        disabled={!(manufacturer && currentArticle.product && currentArticle.service)}
                                        onChange={(e) => updateArticle(e.target.value, 'notes', currentArticle, articleIndex)}
                                        onBlur={(e) => updateArticle(e.target.value.trim(), 'notes', currentArticle, articleIndex)}
                                        placeholder={blok.notes_placeholder}
                                    />
                                </div>
                            </>
                        )}
                    </form>
                    {children}
                </div>
            )}
        </>
    );
};

export default Article;
