import React from 'react';
import { IAddress } from '../../../../_shared/interfaces/address';
import { StoryblokCommon } from '../../../../lib/storyblok/storyblok';

import * as AddressStyles from './address.module.scss';

const OverviewAddress = ({
    address,
    underline,
    storyblokCommon,
}: {
    address: IAddress;
    underline: boolean;
    storyblokCommon: StoryblokCommon;
}) => {
    return (
        <div className={AddressStyles.overviewAddress}>
            <p>
                <strong>
                    {address.company}
                    {address.supplement && <> ({address.supplement})</>}
                </strong>
            </p>
            <p>
                {address.street}, {address.postalCode} {address.city},{' '}
                {storyblokCommon.configuration.content.countries.find((c) => c.id === address.country)?.name}
            </p>
            {underline && <div className={AddressStyles.underline} />}
        </div>
    );
};

export default OverviewAddress;
