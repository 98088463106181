import React from 'react';

import * as EntryStyles from './entry.module.scss';

const OverviewEntry = ({
    title,
    text,
    additionalText,
    underline,
}: {
    title: string;
    text?: string;
    additionalText?: string;
    underline?: boolean;
}) => {
    return (
        <>
            <div className={EntryStyles.entry}>
                <label className={!text && !additionalText ? EntryStyles.standAloneLabel : ''}>{title}</label>
                {text && <p>{text}</p>}
                {additionalText && (
                    <p>
                        <small>{additionalText}</small>
                    </p>
                )}
                {underline ? <div className={EntryStyles.underline} /> : <></>}
            </div>
        </>
    );
};

export default OverviewEntry;
