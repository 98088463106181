import React, { FC } from 'react';
import * as ModalStyles from '../../../_scss/modules/_modal.module.scss';
import * as AdditionalServicesStyles from './additional-services.module.scss';

type Props = {
    children: React.ReactElement<Props>;
};

const Modal: FC<Props> = ({ children }) => (
    <div className={ModalStyles.modal}>
        <div className={`${ModalStyles.inner} ${AdditionalServicesStyles.inner}`}>{children}</div>
    </div>
);

export default Modal;
