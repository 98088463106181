import React, { useMemo } from 'react';
import { useShoppingCartDetails, useShoppingCartStoryBlok, useShoppingCartStyles } from './shopping-cart-context';
import { IShoppingCartDetailsDeliveryService } from '../../../_shared/interfaces/order';

const ShoppingCartDelivery = () => {
    const styles = useShoppingCartStyles();
    const blok = useShoppingCartStoryBlok();
    const { taxAmount: { formatted = '' } = {}, deliveryService = {} as IShoppingCartDetailsDeliveryService } = useShoppingCartDetails();

    return useMemo(
        () => (
            <div className={styles.section}>
                <p className={styles.sectionName}>{blok.delivery_from_workshop}</p>
                <p className={styles.price}>{deliveryService.formatted ?? ''}</p>
                <p className={styles.sectionName}>{blok.vat}</p>
                <p className={styles.price}>{formatted}</p>
            </div>
        ),
        [formatted, deliveryService.formatted],
    );
};

export default ShoppingCartDelivery;
