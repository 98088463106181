import React, { FC, useMemo } from 'react';
import { useShoppingCartStoryBlok, useShoppingCartStoryBlokCommon, useShoppingCartStyles } from './shopping-cart-context';
import { useOrderStep } from '../../../context/order/order-step-context';
import HsLink from '../../_common/hs-link/hs-link';
import { navigate } from 'gatsby';

type Props = {
    isCostEstimate: boolean;
};

const ShoppingCartHeader: FC<Props> = ({ isCostEstimate }) => {
    const styles = useShoppingCartStyles();
    const blok = useShoppingCartStoryBlok();
    const storyblokCommon = useShoppingCartStoryBlokCommon();
    const step = useOrderStep();
    return useMemo(
        () => (
            <div className={styles.section}>
                <h6 className={styles.sectionName}>{blok.headline}</h6>
                {step !== 'services' && (
                    <div className={styles.sectionAction}>
                        {!isCostEstimate && (
                            <HsLink
                                onClick={() => navigate(self.location.pathname, { state: { step: 'services' } })}
                                storyblokCommon={storyblokCommon}
                            >
                                {blok.change_button}
                            </HsLink>
                        )}
                    </div>
                )}
            </div>
        ),
        [step],
    );
};
export default ShoppingCartHeader;
