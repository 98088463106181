// extracted by mini-css-extract-plugin
export var additionalCosts = "shopping-cart-hs-module--additionalCosts--f7b96";
export var costEstimateInfo = "shopping-cart-hs-module--cost-estimate-info--b7542";
export var deleteButton = "shopping-cart-hs-module--delete-button--1f5a6";
export var editButtonContainer = "shopping-cart-hs-module--edit-button-container--f4113";
export var grey = "shopping-cart-hs-module--grey--fe0f1";
export var header = "shopping-cart-hs-module--header--13a58";
export var icon = "shopping-cart-hs-module--icon--27180";
export var orderShoppingCart = "shopping-cart-hs-module--order-shopping-cart--1c61e";
export var price = "shopping-cart-hs-module--price--a71f8";
export var productName = "shopping-cart-hs-module--product-name--77dc4";
export var section = "shopping-cart-hs-module--section--240c2";
export var sectionAction = "shopping-cart-hs-module--section-action--1477f";
export var sectionName = "shopping-cart-hs-module--section-name--14d37";
export var separator = "shopping-cart-hs-module--separator--1c9f8";
export var subtotalName = "shopping-cart-hs-module--subtotal-name--be202";
export var total = "shopping-cart-hs-module--total--2e2fb";
export var totalPrice = "shopping-cart-hs-module--total-price--b14b0";
export var vat = "shopping-cart-hs-module--vat--d7fb9";