import React, { useContext } from 'react';
import { OrderContext } from '../../../../context/order/order.context';
import { handleAzureAdB2cLinks } from '../../../../lib/order/order';
import AddressForm from '../../../_common/address-form/address-form';
import { defaultAddress, isRichText } from '../../../../lib/helpers/helpers';
import HsButton from '../../../_common/hs-button/hs-button';
import { IAddress } from '../../../../_shared/interfaces/address';
import { FormattedNumber } from 'react-intl';
import AddressListPicker from '../../../_common/address-list-picker/address-list-picker';
import { AccountContext } from '../../../../context/account/account.context';
import LoadingAnimationContext from '../../../../context/loading-animation/loading-animation.context';
import { getRichTextMarkup, StoryblokCommon } from '../../../../lib/storyblok/storyblok';
import { ManufacturerStoryblok, OrderDeliveryToCustomerStoryblok } from '../../../../_shared/interfaces/storyblok';

import * as OrderDeliveryToCustomerStylesHs from './to-customer.hs.module.scss';
import * as OrderDeliveryToCustomerStylesDr from './to-customer.dr.module.scss';

import * as FormStylesHs from '../../../../_scss/modules/_forms.hs.module.scss';
import * as FormStylesDr from '../../../../_scss/modules/_forms.dr.module.scss';

export const OrderDeliveryToCustomer = ({
    showErrors,
    storyblokCommon,
    blok,
}: {
    showErrors: boolean;
    storyblokCommon: StoryblokCommon;
    blok: OrderDeliveryToCustomerStoryblok;
}) => {
    const OrderDeliveryToCustomerStyles =
        storyblokCommon.configuration.content.theme === 'dr' ? OrderDeliveryToCustomerStylesDr : OrderDeliveryToCustomerStylesHs;
    const FormStyles = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr : FormStylesHs;
    const { order, updateOrder, isRestored } = React.useContext(OrderContext);
    const accountContext = React.useContext(AccountContext);
    const loadingAnimationContext = useContext(LoadingAnimationContext);
    const [initialAddress, setInitialAddress] = React.useState<IAddress>(order.deliveryToCustomer?.address ?? defaultAddress);
    const [orderDeliveryToCustomerType, setOrderDeliveryToCustomerType] = React.useState<'service_provider' | 'self_pickup'>(
        order.deliveryToCustomer?.type ?? 'service_provider',
    );
    const [manufacturerData, setManufacturerData] = React.useState<ManufacturerStoryblok>();

    const onReturnShipmentChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedType = e.target.value as 'self_pickup' | 'service_provider';

        setOrderDeliveryToCustomerType(selectedType);

        updateOrder({
            ...order,
            deliveryToCustomer: {
                ...order.deliveryToCustomer,
                price: selectedType === 'self_pickup' ? manufacturerData?.self_pickup_cost : manufacturerData?.delivery_costs,
                type: selectedType,
            },
        });
    };

    const setDeliveryToCustomerFields = (fields: any): void => {
        updateOrder({
            ...order,
            deliveryToCustomer: {
                ...order.deliveryToCustomer,
                ...fields,
            },
        });
    };

    const getInitialAddress = (): any => {
        const address: any = { ...defaultAddress };
        if (order.deliveryToCustomer?.address) {
            const currentAddress: any = order.deliveryToCustomer.address;

            for (const addressProp in currentAddress) {
                if (currentAddress.hasOwnProperty(addressProp) && currentAddress[addressProp]) {
                    address[addressProp] = currentAddress[addressProp];
                }
            }
        }
        return address;
    };

    const copyAdress = (): void => {
        if (order.deliveryFromCustomer?.pickupDetails?.address) {
            setInitialAddress({ ...order.deliveryFromCustomer.pickupDetails.address });
            setDeliveryToCustomerFields({ address: order.deliveryFromCustomer.pickupDetails.address });
        }
    };

    const getCurrentManufacturerData = (manufacturer: string | undefined): ManufacturerStoryblok | undefined => {
        if (!manufacturer) {
            return undefined;
        }
        const md = storyblokCommon.workshopServices.content.manufacturer.find((m) => m.name === manufacturer);
        if (!md) {
            return undefined;
        }
        return md;
    };

    const handlePageClick = (e: MouseEvent): void => {
        handleAzureAdB2cLinks(e, accountContext, storyblokCommon);
    };

    const setDefault = () => {
        updateOrder({
            ...order,
            deliveryToCustomer: {
                ...order.deliveryToCustomer,
                price: manufacturerData?.delivery_costs,
                type: 'service_provider',
            },
        });
    };

    React.useEffect(() => {
        if (isRestored) {
            setInitialAddress(getInitialAddress());
        }
    }, [isRestored]);

    React.useEffect(() => {
        if (manufacturerData && !order.deliveryToCustomer?.type) {
            setDefault();
        }
    }, [manufacturerData]);

    React.useEffect(() => {
        setManufacturerData(getCurrentManufacturerData(order.manufacturer));

        if (accountContext.account && !accountContext.addresses) {
            loadingAnimationContext.showLoadingAnimation();
            accountContext.addressesRequest().finally(() => {
                loadingAnimationContext.hideLoadingAnimation();
            });
        }

        if (typeof window !== 'undefined') {
            document.addEventListener('click', handlePageClick);
            return () => {
                document.removeEventListener('click', handlePageClick);
            };
        }
    }, []);

    return (
        <>
            {OrderDeliveryToCustomerStyles && FormStyles && isRestored && (
                <div>
                    <div className={OrderDeliveryToCustomerStyles.header}>
                        <h5>{blok.headline}</h5>
                    </div>
                    <form>
                        <ul className={OrderDeliveryToCustomerStyles.deliveryTypesList}>
                            <li>
                                <div
                                    className={[FormStyles.formField, FormStyles.radio, OrderDeliveryToCustomerStyles.formField].join(' ')}
                                >
                                    <input
                                        type="radio"
                                        id="order-delivery-to-customer-ups"
                                        name="order-delivery-to-customer"
                                        value="service_provider"
                                        checked={orderDeliveryToCustomerType === 'service_provider'}
                                        onChange={onReturnShipmentChanged}
                                    />
                                    <label className={FormStyles.radioLabel} htmlFor={'order-delivery-to-customer-ups'}>
                                        {blok.return_shipping_label}
                                    </label>
                                    <div className={OrderDeliveryToCustomerStyles.price}>
                                        <p>
                                            <FormattedNumber
                                                value={manufacturerData?.delivery_costs ?? 0}
                                                style="currency"
                                                currency={storyblokCommon.configuration.content.currency}
                                            />
                                        </p>
                                    </div>
                                </div>

                                {/*START:  Show address only if UPS is selected */}
                                {orderDeliveryToCustomerType === 'service_provider' && (
                                    <div className={OrderDeliveryToCustomerStyles.addressForm}>
                                        <div>
                                            <p className={OrderDeliveryToCustomerStyles.labelHeadline}>{blok.address_headline}</p>
                                            {accountContext.account ? (
                                                <>
                                                    <AddressListPicker
                                                        storyblokCommon={storyblokCommon}
                                                        selectedId={order.deliveryToCustomer?.addressId}
                                                        onAddressChange={(addressId) => {
                                                            setDeliveryToCustomerFields({
                                                                address: accountContext.addresses?.find(
                                                                    (address) => address.id === addressId,
                                                                ),
                                                                addressId,
                                                            });
                                                        }}
                                                    />
                                                    {showErrors && !order.deliveryToCustomer?.address && (
                                                        <p
                                                            className={[FormStyles.error, OrderDeliveryToCustomerStyles.addressError].join(
                                                                ' ',
                                                            )}
                                                        >
                                                            {blok.address_error}
                                                        </p>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {order.deliveryFromCustomer?.type === 'pickup' && (
                                                        <HsButton
                                                            className={OrderDeliveryToCustomerStyles.button}
                                                            appearance="secondary"
                                                            onClick={copyAdress}
                                                            storyblokCommon={storyblokCommon}
                                                        >
                                                            {blok.address_copy_button}
                                                        </HsButton>
                                                    )}

                                                    <AddressForm
                                                        storyblokCommon={storyblokCommon}
                                                        initialAddress={initialAddress}
                                                        onChange={(address: any) => setDeliveryToCustomerFields({ address })}
                                                        showErrors={showErrors}
                                                    />
                                                    <div className={OrderDeliveryToCustomerStyles.delimiter} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {/*END:  Show address only if UPS is selected */}
                            </li>
                            {manufacturerData?.self_pickup && (
                                <li>
                                    <div
                                        className={[FormStyles.formField, FormStyles.radio, OrderDeliveryToCustomerStyles.formField].join(
                                            ' ',
                                        )}
                                    >
                                        <input
                                            id="order-delivery-to-customer-self-pickup"
                                            type="radio"
                                            name="order-delivery-to-customer"
                                            value="self_pickup"
                                            checked={orderDeliveryToCustomerType === 'self_pickup'}
                                            onChange={onReturnShipmentChanged}
                                        />
                                        <label className={FormStyles.radioLabel} htmlFor={'order-delivery-to-customer-self-pickup'}>
                                            {blok.self_pickup_label}
                                        </label>

                                        <div className={OrderDeliveryToCustomerStyles.price}>
                                            <p>
                                                <FormattedNumber
                                                    value={manufacturerData?.self_pickup_cost ?? 0}
                                                    style="currency"
                                                    currency={storyblokCommon.configuration.content.currency}
                                                />
                                            </p>
                                        </div>
                                    </div>

                                    {orderDeliveryToCustomerType === 'self_pickup' && (
                                        <div className={OrderDeliveryToCustomerStyles.addressForm}>
                                            <p className={OrderDeliveryToCustomerStyles.labelHeadline}>{blok.address_headline}</p>
                                            {accountContext.account ? (
                                                <>
                                                    <AddressListPicker
                                                        storyblokCommon={storyblokCommon}
                                                        selectedId={order.deliveryToCustomer?.addressId}
                                                        onAddressChange={(addressId) => {
                                                            setDeliveryToCustomerFields({
                                                                address: accountContext.addresses?.find(
                                                                    (address) => address.id === addressId,
                                                                ),
                                                                addressId,
                                                            });
                                                        }}
                                                    />
                                                    {showErrors && !order.deliveryToCustomer?.address && (
                                                        <p
                                                            className={[FormStyles.error, OrderDeliveryToCustomerStyles.addressError].join(
                                                                ' ',
                                                            )}
                                                        >
                                                            {blok.address_error}
                                                        </p>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {order.deliveryFromCustomer?.type === 'pickup' && (
                                                        <HsButton
                                                            className={OrderDeliveryToCustomerStyles.button}
                                                            appearance="secondary"
                                                            onClick={copyAdress}
                                                            storyblokCommon={storyblokCommon}
                                                        >
                                                            {blok.address_copy_button}
                                                        </HsButton>
                                                    )}

                                                    <AddressForm
                                                        storyblokCommon={storyblokCommon}
                                                        initialAddress={initialAddress}
                                                        onChange={(address: any) => setDeliveryToCustomerFields({ address })}
                                                        showErrors={showErrors}
                                                    />
                                                </>
                                            )}
                                            {blok.self_pickup_description && isRichText(blok.self_pickup_description) && (
                                                <div
                                                    dangerouslySetInnerHTML={getRichTextMarkup(blok.self_pickup_description)}
                                                    style={{ paddingTop: '24px' }}
                                                />
                                            )}
                                        </div>
                                    )}
                                </li>
                            )}
                        </ul>
                    </form>
                </div>
            )}
        </>
    );
};

export default OrderDeliveryToCustomer;
