import React from 'react';
import Layout from '../components/_common/_layout/layout';
import SEO from '../components/_common/seo/seo';
import OrderNavigation, { getCurrentStep } from '../components/order/navigation/navigation';
import OrderLayout from '../components/order/_layout/layout';
import OrderError from '../components/order/error/error';
import OrderSuccess from '../components/order/success/success';
import { OrderContext } from '../context/order/order.context';
import { trackEvent, trackPage } from '../lib/track/track';
import { deepLinkCheck } from '../lib/deep-linking/deep-linking';
import { useStoryBlockCommon, useStoryblok } from '../lib/storyblok/storyblok';
import { PageOrderStoryblok } from '../_shared/interfaces/storyblok';
import { IntlProvider } from 'react-intl';
import SbEditable from 'storyblok-react';
import { AdditionalServicesProvider } from '../components/order/additional-services/additional-services-context';
import { OrderStepProvider } from '../context/order/order-step-context';
import { ICostEstimate } from '../_shared/interfaces/cost-estimate';
import { ModalContextProvider } from '../context/modal/modal.context';
import { PageAccountStoryblokProvider } from '../context/storyblok/page-account-storyblok.context';

export type OrderStep = 'services' | 'delivery' | 'payment' | 'overview' | 'success' | 'error' | 'cost-estimate';

const OrderPage = ({ pageContext }: { pageContext: { story: { content: PageOrderStoryblok; full_slug: string } } }) => {
    const orderContext = React.useContext(OrderContext);
    const step: OrderStep = (typeof window !== 'undefined' && history.state?.step) ?? getCurrentStep(orderContext);
    const costEstimate: ICostEstimate | undefined = typeof window !== 'undefined' && history.state?.costEstimate;
    let story = pageContext.story;
    story = useStoryblok(story);
    const storyblokCommon = useStoryBlockCommon(story.full_slug);
    const [manufacturerChecked, setManufacturerChecked] = React.useState(false);

    const checkStoredManufacturer = (): void => {
        if (typeof window !== `undefined`) {
            deepLinkCheck(window.location, storyblokCommon.workshopServices.content)
                .then((deepLinkOrder) => {
                    if (deepLinkOrder) {
                        orderContext.resetOrder().then(() => {
                            orderContext.updateOrder(deepLinkOrder);
                        });
                    }
                })
                .finally(() => {
                    if (!orderContext.order.manufacturer) {
                        setManufacturerChecked(true);
                    } else {
                        const manufacturer = storyblokCommon.workshopServices.content.manufacturer.find(
                            (m) => m.name === orderContext.order.manufacturer,
                        );
                        if (!manufacturer || (orderContext.order.costEstimationParentSysId && step !== 'cost-estimate')) {
                            orderContext.resetOrder().then(() => {
                                setManufacturerChecked(true);
                            });
                        } else {
                            setManufacturerChecked(true);
                        }
                    }
                });
        }
    };

    React.useEffect(() => {
        if (orderContext.isRestored) {
            checkStoredManufacturer();
        }
    }, [orderContext.isRestored]);

    React.useEffect(() => {
        trackPage({
            country: process.env.GATSBY_COUNTRY,
            language: storyblokCommon.configuration.content.language,
            title: story.content.metadata.title,
            template: `Order${step.charAt(0).toUpperCase() + step.slice(1)}`,
        });
    }, []);

    return (
        <IntlProvider locale={storyblokCommon.configuration.content.language}>
            <AdditionalServicesProvider>
                <OrderStepProvider>
                    <PageAccountStoryblokProvider blok={storyblokCommon.accountDetailPage.content}>
                        <ModalContextProvider>
                            <Layout storyblokCommon={storyblokCommon}>
                                <SEO
                                    metadata={story.content.metadata}
                                    config={storyblokCommon.configuration.content}
                                    full_slug={story.full_slug}
                                />
                                {manufacturerChecked && (
                                    <>
                                        {step !== 'success' && step !== 'error' && (
                                            <>
                                                <SbEditable content={story.content.order_navigation[0]}>
                                                    <OrderNavigation
                                                        blok={story.content.order_navigation[0]}
                                                        step={step}
                                                        storyblokCommon={storyblokCommon}
                                                        isCostEstimate={step === 'cost-estimate'}
                                                    />
                                                </SbEditable>
                                                <OrderLayout
                                                    pageContext={story.content}
                                                    step={step}
                                                    storyblokCommon={storyblokCommon}
                                                    costEstimate={costEstimate}
                                                />
                                            </>
                                        )}
                                        {step === 'success' && (
                                            <SbEditable content={story.content.order_success[0]}>
                                                <OrderSuccess
                                                    blok={story.content.order_success[0]}
                                                    customerEmail={typeof window !== 'undefined' ? history.state?.email : ''}
                                                    currentOrder={typeof window !== 'undefined' ? history.state?.currentOrder : {}}
                                                    storyblokCommon={storyblokCommon}
                                                    costEstimateEnabled={!!story.content.order_cost_estimate_enabled}
                                                />
                                            </SbEditable>
                                        )}
                                        {step === 'error' && (
                                            <OrderError blok={story.content.order_error[0]} storyblokCommon={storyblokCommon} />
                                        )}
                                    </>
                                )}
                            </Layout>
                        </ModalContextProvider>
                    </PageAccountStoryblokProvider>
                </OrderStepProvider>
            </AdditionalServicesProvider>
        </IntlProvider>
    );
};

export default OrderPage;
