import React, { FC, ReactElement } from 'react';
import { useShoppingCartStyles } from './shopping-cart-context';

type Props = {
    children: ReactElement<any> | ReactElement<any>[];
};

const ShoppingCartContainer: FC<Props> = ({ children }) => {
    const styles = useShoppingCartStyles();
    return <div className={styles.orderShoppingCart}>{children}</div>;
};

export default ShoppingCartContainer;
