import React, { FC, useMemo } from 'react';
import { useShoppingCartStyles, useShoppingCartStoryBlok, useShoppingCartDetails } from './shopping-cart-context';

const ShoppingCartTotal: FC = () => {
    const styles = useShoppingCartStyles();
    const { total, vat_included } = useShoppingCartStoryBlok();
    const { grandTotal: { formatted } = {} } = useShoppingCartDetails();

    const Total = useMemo(
        () => (
            <div className={styles.section}>
                <h6 className={`${styles.total} ${styles.total}`} style={{ margin: 0 }}>
                    {total.toUpperCase()}
                </h6>

                <p className={`${styles.price} ${styles.totalPrice}`}>
                    <strong>{formatted}</strong>
                </p>
                <p className={styles.vat}>
                    <small>{vat_included}</small>
                </p>
            </div>
        ),
        [formatted],
    );
    return Total;
};
export default ShoppingCartTotal;
